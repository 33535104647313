// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home_page{
    background-color: rgb(249, 250, 251);
    height:100vh;
    width:100%;
}
.home-loader-sidebar{
   width:18rem;
}
.sidebar-loading-item{
    background: rgba(171, 163, 163,0.5);
    border-radius: 0.25rem;
    animation:home-loader 0.8s ;
    animation-iteration-count: infinite;
   
}
.home-load-filler{
    width:20%;
    background-color: red;
}
@keyframes home-loader {
    from{
        background-color: rgba(201, 203, 221, 0.5);
    }  
    to{
        background-color: rgba(171, 163, 163,0.3);
    } 
}`, "",{"version":3,"sources":["webpack://./src/components/Home/home.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,YAAY;IACZ,UAAU;AACd;AACA;GACG,WAAW;AACd;AACA;IACI,mCAAmC;IACnC,sBAAsB;IACtB,2BAA2B;IAC3B,mCAAmC;;AAEvC;AACA;IACI,SAAS;IACT,qBAAqB;AACzB;AACA;IACI;QACI,0CAA0C;IAC9C;IACA;QACI,yCAAyC;IAC7C;AACJ","sourcesContent":[".home_page{\n    background-color: rgb(249, 250, 251);\n    height:100vh;\n    width:100%;\n}\n.home-loader-sidebar{\n   width:18rem;\n}\n.sidebar-loading-item{\n    background: rgba(171, 163, 163,0.5);\n    border-radius: 0.25rem;\n    animation:home-loader 0.8s ;\n    animation-iteration-count: infinite;\n   \n}\n.home-load-filler{\n    width:20%;\n    background-color: red;\n}\n@keyframes home-loader {\n    from{\n        background-color: rgba(201, 203, 221, 0.5);\n    }  \n    to{\n        background-color: rgba(171, 163, 163,0.3);\n    } \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
