import "./user.css";
import { useEffect, useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import edit_icon from '../../assets/edit_icon.svg';
import delete_icon from '../../assets/delete_icon.svg';
import cancel_icon from '../../assets/close_icon.svg';
import Context from '../../user_details';
export default function User(props) {
    const [users, setUsers] = useState([]);
    const [users_list, setUsersList] = useState([]);
    const [deleted_users, setDeletedUsers] = useState(new Set());
    const userDetails = useContext(Context);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    console.log(userDetails, "this is userDetials")
    useEffect(() => {
        if (users_list.length === 0) {
            get_users_list();
        }
        return () => { };
    }, []);

    useEffect(() => {
        console.log("Response is ==========", users_list)
        if (users_list.length > 0) {
            setLoading(false);
        }
    }, [users_list])

    useEffect(() => {
        console.log(props, "this is initial deleted users", deleted_users)
        return console.log("this is initial deleted users", deleted_users)
    }, [deleted_users])

    const get_users_list = async () => {
        setLoading(true);
        const usersList = await fetch(process.env.REACT_APP_API_URL + "/api/admin/list/?type=" + userDetails.user.user_type + "&firm_id=" + userDetails.user.firm_id)
            .then(async res => {
                const result = await res.json();
                setUsersList(result.resultsPromise);
            })
            .catch(err => {
                console.log(err)
            })
    }

    const CreateProfile = () => {
        navigate("/create-profile")
    }
    const UpdateProfile = (userId, firmId) => {
        navigate(`/update-profile?userId=${userId}&firmId=${firmId}`)
    }
    const Search_user = (event) => {
        let x = users.filter(element => {
            let { username } = element;
            let value = event.target.value.trim()
            if (username.indexOf(value) >= 0) {
                return element
            }
        })
        setUsersList(x);
    }

    const deleteProfile = async (event) => {
        let del_users = deleted_users;
        var selected_user = event.target;
        var delete_user_id = event.target.parentNode.getAttribute("value");
        if (selected_user.tagName !== 'IMG') {
            selected_user = selected_user.childNodes[0];
            delete_user_id = event.target.getAttribute("value");
        }
        if (selected_user.className === "delete_icon") {
            selected_user.src = cancel_icon;
            selected_user.className = "cancel_icon";
            del_users.add(delete_user_id);

        }
        else {
            selected_user.src = delete_icon;
            selected_user.className = "delete_icon";
            if (del_users.has(delete_user_id)) del_users.delete(delete_user_id)
        }
        await setDeletedUsers(del_users);
        navigate("/user");
        console.log("this is deleted users,", deleted_users)
    }

    const delete_users = async () => {
        for (let user of deleted_users) {
            var result = await fetch(process.env.REACT_APP_API_URL + "/api/admin/delete/" + user, {
                method: "DELETE"
            })
                .then(async res => {
                    return await res.json()
                })
            console.log(result);

            setDeletedUsers(new Set());
            await get_users_list();
            navigate("/user")
        }
    }
    if (!loading) {
        return (
            userDetails && userDetails != null && userDetails != undefined ?
                <div className="user_page">
                    <div className="options_container d-flex align-items-center justify-content-end gap-2">
                        {deleted_users.size > 0 ? <div onClick={delete_users} className="btn btn-danger">Delete</div> : ""}
                        <button className="btn btn-primary" onClick={CreateProfile}>+ New User</button>
                    </div>
                    <div className="user_cards_container">
                        <div className="">
                            <div className="user_detail_card_headings  text-white p-2 bg-primary d-flex">
                                <div className={"fw-bold " + (userDetails?.user?.user_type == 'superAdmin' ? "user_detail_field_superAdmin" : "user_detail_field")}>Name</div>
                                {userDetails?.user?.user_type === 'superAdmin' ? <span className={"fw-bold " + (userDetails?.user?.user_type == 'superAdmin' ? "user_detail_field_superAdmin" : "user_detail_field")}>Firm</span> : ""}
                                <div className={"fw-bold " + (userDetails?.user?.user_type == 'superAdmin' ? "user_detail_field_superAdmin" : "user_detail_field")}>Role</div>
                                <div className={"fw-bold " + (userDetails?.user?.user_type == 'superAdmin' ? "user_detail_field_superAdmin" : "user_detail_field")}>Batches</div>
                                <div className={"fw-bold " + (userDetails?.user?.user_type == 'superAdmin' ? "user_detail_field_superAdmin" : "user_detail_field")}>Email</div>
                                <div className={"fw-bold " + (userDetails?.user?.user_type == 'superAdmin' ? "user_detail_field_superAdmin" : "user_detail_field")}>Status</div>
                            </div>

                            <div className="user_details_table">
                                {
                                    loading === true ?
                                        <div className="h-100 w-100 d-flex justify-content-center bg-white align-items-center">
                                            <div className="text-center text-primary fw-bolder fs-2">
                                                <div className="spinner-border" role="status"></div>
                                            </div>
                                        </div> :
                                        users_list.length === 0 && users_list ?
                                            <div style={{ height: "90%" }} className="no_user bg-white d-flex flex-column justify-content-center align-items-center">
                                                <img height="50%" src={require("../../assets/user_not_found.svg").default} alt="user not found" />
                                                <p className="fw-bold">No Users Found</p>
                                            </div> :
                                            <div className="table_elements_container" >
                                                {!users_list ? '' : users_list?.map((user, index) => {
                                                    return (
                                                        <div key={index} className="user_detail_card p-2 bg-white d-flex align-items-center">
                                                            <div className={"fw-bold " + (userDetails?.user?.user_type == 'superAdmin' ? "user_detail_field_superAdmin" : "user_detail_field")}>{user?.user?.first_name} {user?.user?.last_name}</div>
                                                            {userDetails && userDetails?.user?.user_type === 'superAdmin' ? <div className={"fw-bold " + (userDetails?.user?.user_type == 'superAdmin' ? "user_detail_field_superAdmin" : "user_detail_field")}>{user.firmDetails?.firm_name} </div> : ""}
                                                            <div className={"fw-bold " + (userDetails?.user?.user_type == 'superAdmin' ? "user_detail_field_superAdmin" : "user_detail_field")}>{
                                                                user?.roles?.map((role, index) => {
                                                                    return <span key={index}>{role.role_name}<br /></span>
                                                                })
                                                            }

                                                            </div>
                                                            <div className={"fw-bold " + (userDetails?.user?.user_type == 'superAdmin' ? "user_detail_field_superAdmin" : "user_detail_field")}>
                                                                {
                                                                    user?.user?.groups_assigned?.map((group, index) => {
                                                                        return <span key={index}>{group.name}<br /></span>
                                                                    })
                                                                }

                                                            </div>
                                                            <div className={"fw-bold " + (userDetails?.user?.user_type == 'superAdmin' ? "user_detail_field_superAdmin" : "user_detail_field")}>{user?.user?.email}</div>
                                                            <div className={"fw-bold " + (userDetails?.user?.user_type == 'superAdmin' ? "user_detail_field_superAdmin" : "user_detail_field")}>{user?.user?.status}</div>
                                                            <div className={"fw-bold d-flex gap-1 justify-content-center align-items-center" + (userDetails?.user?.user_type == 'superAdmin' ? "user_detail_field_superAdmin" : "user_detail_field")} style={{ height: "100%" }}>
                                                                <div className="btn btn-primary update_button" onClick={() => { UpdateProfile(user?.user._id, user?.user.firm_id) }}>
                                                                    <img className="edit_icon" src={edit_icon} height="20" />
                                                                </div>
                                                                <div className="btn btn-danger" value={user?.user?._id} id={user?.user?._id} onClick={deleteProfile}>
                                                                    <img className="delete_icon" src={delete_icon} height="20" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                }

                            </div>
                        </div>





                    </div>
                </div> : <div className="h-100 w-100 d-flex justify-content-center bg-white align-items-center">
                    <div className="text-center text-primary fw-bolder fs-2">
                        <div className="spinner-border" role="status"></div>
                    </div>
                </div>
        )
    }
    else {
        return <div className='h-100 w-100 d-flex justify-content-center align-items-center'>
            <div className="text-center text-primary fw-bolder fs-2">
                <div className="spinner-border" role="status"></div>
            </div>
        </div>
    }
}