// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/login_istock.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login_page{
    height: 100vh;
    width: 100%;
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    background-repeat: no-repeat;
    background-position:center;
}
.login_container{
    background: rgba(255,255,255,0.87);
    border: 3px solid rgb(25, 81, 149);
    padding:24px;
    padding-left:5vw;
    padding-right:5vw;
    border-radius:24px;
    box-shadow: 15px 15px 25px black;
}
.input_text{
    color: rgb(25, 81, 149);
    font-weight: bold;
    /* font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif */
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.input_element{
    border:1px solid rgb(12, 135, 218);
    border-radius:12px;
    padding:2px;
    padding-left:4px;
    color:rgb(50, 109, 143);
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif
}
.input_element:focus{
   border:1px solid rgb(10, 34, 141);
}
.login_button{
    background-color: rgb(13, 25, 80);
    padding:3px;
    padding-left:12px;
    padding-right:12px;
    color:white;
    font-family:Arial, Helvetica, sans-serif;   
    border:none;
    font-weight:bold;
    border-radius:6px;    
}
`, "",{"version":3,"sources":["webpack://./src/components/Login/login.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,mDAAgD;IAChD,sBAAsB;IACtB,4BAA4B;IAC5B,0BAA0B;AAC9B;AACA;IACI,kCAAkC;IAClC,kCAAkC;IAClC,YAAY;IACZ,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;IAClB,gCAAgC;AACpC;AACA;IACI,uBAAuB;IACvB,iBAAiB;IACjB,4EAA4E;IAC5E,sHAAsH;AAC1H;AACA;IACI,kCAAkC;IAClC,kBAAkB;IAClB,WAAW;IACX,gBAAgB;IAChB,uBAAuB;IACvB;AACJ;AACA;GACG,iCAAiC;AACpC;AACA;IACI,iCAAiC;IACjC,WAAW;IACX,iBAAiB;IACjB,kBAAkB;IAClB,WAAW;IACX,wCAAwC;IACxC,WAAW;IACX,gBAAgB;IAChB,iBAAiB;AACrB","sourcesContent":[".login_page{\n    height: 100vh;\n    width: 100%;\n    background: url('../../assets/login_istock.jpg');\n    background-size: cover;\n    background-repeat: no-repeat;\n    background-position:center;\n}\n.login_container{\n    background: rgba(255,255,255,0.87);\n    border: 3px solid rgb(25, 81, 149);\n    padding:24px;\n    padding-left:5vw;\n    padding-right:5vw;\n    border-radius:24px;\n    box-shadow: 15px 15px 25px black;\n}\n.input_text{\n    color: rgb(25, 81, 149);\n    font-weight: bold;\n    /* font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif */\n    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;\n}\n.input_element{\n    border:1px solid rgb(12, 135, 218);\n    border-radius:12px;\n    padding:2px;\n    padding-left:4px;\n    color:rgb(50, 109, 143);\n    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif\n}\n.input_element:focus{\n   border:1px solid rgb(10, 34, 141);\n}\n.login_button{\n    background-color: rgb(13, 25, 80);\n    padding:3px;\n    padding-left:12px;\n    padding-right:12px;\n    color:white;\n    font-family:Arial, Helvetica, sans-serif;   \n    border:none;\n    font-weight:bold;\n    border-radius:6px;    \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
