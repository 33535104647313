import { Outlet, useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import './home.css'
import Sidebar from '../Sidebar/sidebar'
import Logo from '../../assets/logo2.jpeg'
import { useEffect, useState } from 'react'
import ProfileCreate from '../ProfileCreate/create'
import DashBoard from '../Dashboards/dashboard';
import userDetailsContext from '../../user_details';
import * as React from 'react';
import logo from '../../assets/NexusIQ-FinalVersion.jpg';
import '../Sidebar/sidebar.css';

function Home() {
    const user_id = Cookies.get("nexus_user_id");
    const [loading, setLoading] = useState(true)
    const [user, setUser] = useState({});
    const navigate = useNavigate()

    useEffect(() => {
        userDetailsApi();
        return () => {
        };
    }, [])

    useEffect(() => {
        if (user && Object.keys(user).length > 0) {
            setLoading(false);
        }
    }, [user])

    // useEffect(()=>{
    //     console.log("===========user details==============\n",user,"\n======================================")
    // },[user])

    const userDetailsApi = async () => {
       try {
        const user_details = await fetch(process.env.REACT_APP_API_URL + "/api/admin/read/" + user_id)
        .then(async res => {
            const result = await res.json()
            setUser(result.result);
        })
        .catch(e => {
            console.log(e);
            Cookies.remove("nexus_login");
            Cookies.remove("nexus_user_id");
        })
       } catch(e) {
        console.log(e);
        Cookies.remove("nexus_login");
        Cookies.remove("nexus_user_id");
       }

    }
    // useEffect(() => {
    //     if (window.location.pathname !== '/' && window.location.pathname !== '/dashboards') window.location.href = "/dashboards"
    // }, [])

    const logout = async () => {
        Cookies.remove("nexus_login");
        Cookies.remove("nexus_user_id");
        window.location.href = "/";
    }

    if (loading == false)
        return (
            <userDetailsContext.Provider value={{ user }}>
                {user && Object.keys(user).length > 0  && <div className="d-flex align-items-stretch home_page">
                    <Sidebar user={user} logout={logout} />
                    <Outlet />
                </div>}
            </userDetailsContext.Provider>
        )
    else {
        return (
            <div className='d-flex align-items-stretch h-100'>
                <div className="sidebar_container d-flex flex-column shadow-sm">
                    <div className="d-flex flex-column align-items-stretch justify-content-center p-5" style={{ height: '10rem' }}><img src={logo} height="50" /></div>
                    <div className="sidebar_element_container justify-content-center h-100 p-2 pt-5" style={{ height: '100%', flexGrow: '10' }}>
                        <div className='sidebar-loading-item  w-100 h-20 p-4 mb-4'><div className='home-load-filler '></div></div>
                        <div className='sidebar-loading-item  w-100 h-20 p-4 mb-4'></div>
                        <div className='sidebar-loading-item  w-100 h-20 p-4 mb-4'></div>
                        <div className='sidebar-loading-item  w-100 h-20 p-4 mb-4'></div>
                        <div className='sidebar-loading-item  w-100 h-20 p-4 mb-4'></div>
                    </div>

                </div>
                <div className="h-100 w-100 d-flex justify-content-center align-items-center">
                    <div className="text-center text-primary fw-bolder fs-1 position-relative" >
                        {/* <span className='spinner-loading-text'>Loading...</span> */}
                        <div className='spinner-position-container'>
                            <div class="spinner-grow text-info" role="status">
                                
                            </div>
                            <div class="spinner-grow text-info" role="status">
                                
                            </div>
                            <div class="spinner-grow text-info" role="status">
                               
                            </div>
                        </div>
                        <div className="spinner-border" role="status" ></div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Home