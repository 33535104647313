// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar_element{
    border-radius:0.2rem;
    padding:1rem;
    padding-top:0.5rem;
    padding-bottom:0.5rem;
    /* color:rgb(99, 115, 129); */
    color:whitesmoke;
    font-family: sans-serif;
    font-size:1rem;    
    cursor:pointer;
    font-weight: 600;
}
.sidebar_icons{
    height:2vw;
    width:2vw;
}
/* .sidebar_element:hover {
    background-color: rgba(24, 119, 242, 0.078);
  
   
} */
.sidebar_element:active{
    /* background-color: rgba(24, 119, 242, 0.27) !important; */
    animation:cubic-bezier(1, 0, 0, 1)
}
`, "",{"version":3,"sources":["webpack://./src/components/Sidebar_Element/sidebar_element.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,YAAY;IACZ,kBAAkB;IAClB,qBAAqB;IACrB,6BAA6B;IAC7B,gBAAgB;IAChB,uBAAuB;IACvB,cAAc;IACd,cAAc;IACd,gBAAgB;AACpB;AACA;IACI,UAAU;IACV,SAAS;AACb;AACA;;;;GAIG;AACH;IACI,2DAA2D;IAC3D;AACJ","sourcesContent":[".sidebar_element{\n    border-radius:0.2rem;\n    padding:1rem;\n    padding-top:0.5rem;\n    padding-bottom:0.5rem;\n    /* color:rgb(99, 115, 129); */\n    color:whitesmoke;\n    font-family: sans-serif;\n    font-size:1rem;    \n    cursor:pointer;\n    font-weight: 600;\n}\n.sidebar_icons{\n    height:2vw;\n    width:2vw;\n}\n/* .sidebar_element:hover {\n    background-color: rgba(24, 119, 242, 0.078);\n  \n   \n} */\n.sidebar_element:active{\n    /* background-color: rgba(24, 119, 242, 0.27) !important; */\n    animation:cubic-bezier(1, 0, 0, 1)\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
