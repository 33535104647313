// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar_container{
    border-right: 2px solid rgba(145, 158, 171, 0.12);
    width:18rem;
}
.profile_card{
    background-color: rgba(145, 158, 171, 0.12);
    color:rgb(15, 45, 51);
    border-radius:0.8rem;
    font-style: italic;
    font-weight: lighter;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.profile_card_icon{
    border: 1px solid rgb(31, 120, 215);
    height:2.5rem;
    width:2.5rem;
    border-radius:100%;
    color:black;
    background-color: white;
}
.sidebar_element_container{
    height:100%;
    background-color: rgb(38, 27, 86);
}
.selected{
  /* background-color: #1877F244; */
  background-color: whitesmoke;
  color: rgb(24, 119, 242);
}
/* .selected:hover{
    background-color: rgba(24, 119, 242, 0.3)!important; 
} */
`, "",{"version":3,"sources":["webpack://./src/components/Sidebar/sidebar.css"],"names":[],"mappings":"AAAA;IACI,iDAAiD;IACjD,WAAW;AACf;AACA;IACI,2CAA2C;IAC3C,qBAAqB;IACrB,oBAAoB;IACpB,kBAAkB;IAClB,oBAAoB;IACpB,wEAAwE;AAC5E;AACA;IACI,mCAAmC;IACnC,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,WAAW;IACX,uBAAuB;AAC3B;AACA;IACI,WAAW;IACX,iCAAiC;AACrC;AACA;EACE,iCAAiC;EACjC,4BAA4B;EAC5B,wBAAwB;AAC1B;AACA;;GAEG","sourcesContent":[".sidebar_container{\n    border-right: 2px solid rgba(145, 158, 171, 0.12);\n    width:18rem;\n}\n.profile_card{\n    background-color: rgba(145, 158, 171, 0.12);\n    color:rgb(15, 45, 51);\n    border-radius:0.8rem;\n    font-style: italic;\n    font-weight: lighter;\n    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;\n}\n.profile_card_icon{\n    border: 1px solid rgb(31, 120, 215);\n    height:2.5rem;\n    width:2.5rem;\n    border-radius:100%;\n    color:black;\n    background-color: white;\n}\n.sidebar_element_container{\n    height:100%;\n    background-color: rgb(38, 27, 86);\n}\n.selected{\n  /* background-color: #1877F244; */\n  background-color: whitesmoke;\n  color: rgb(24, 119, 242);\n}\n/* .selected:hover{\n    background-color: rgba(24, 119, 242, 0.3)!important; \n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
