import "./forms.css";
import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Context from "../../user_details";
import edit_icon from "../../assets/edit_icon.svg";
import delete_icon from "../../assets/delete_icon.svg";
import cancel_icon from "../../assets/close_icon.svg";

export default function Forms(props) {
 const navigate = useNavigate();
 const [forms, setForms] = useState([]);
 const context = useContext(Context);
 const userDetails = context.user;
 const [deleted_users, setDeletedUsers] = useState(new Set());

 useEffect(() => {
  console.log(props, "this is initial deleted users", deleted_users);
  return console.log("this is initial deleted users", deleted_users);
 }, [deleted_users]);

 const getFormsList = async () => {
  try {
   const response = await fetch(process.env.REACT_APP_API_URL + "/api/form/list?page=1&limit=10");
   const result = await response.json();

   // Check if result is an object and has a forms property
   if (result && Array.isArray(result.forms)) {
    setForms(result.forms);
   } else {
    console.error("Expected an array of forms, but got:", result ? result.forms : "undefined");
    setForms([]); // Set to empty array to prevent errors
   }
  } catch (error) {
   console.error("Error fetching forms:", error);
   setForms([]); // Optional: Reset forms to empty on error
  }
 };

 useEffect(() => {
  getFormsList();
 }, []);

 const createForm = () => {
  navigate("/CreateForm");
 };

 const editForm = (formId) => {
  console.log("Navigating to UpdateForm with formId:", formId);
  navigate(`/UpdateForm/${formId}`);
 };
 const deleteProfile = async (event) => {
  //             // Confirmation step before proceeding with the delete
  // const confirmDelete = window.confirm("Are you sure you want to delete this form?");
  // if (!confirmDelete) {
  //     return; // Exit if user cancels the action
  // }
  let del_users = deleted_users;
  const formId = event.currentTarget.getAttribute("value"); // Get form ID from value attribute

  if (formId) {
   let selected_user = event.target;
   if (selected_user.tagName !== "IMG") {
    selected_user = selected_user.childNodes[0];
   }

   if (selected_user.className === "delete_icon") {
    selected_user.src = cancel_icon;
    selected_user.className = "cancel_icon";
    del_users.add(formId);
   } else {
    selected_user.src = delete_icon;
    selected_user.className = "delete_icon";
    del_users.delete(formId);
   }

   setDeletedUsers(new Set(del_users)); // Update the state with the deleted forms
   console.log("Deleted users:", del_users);
  } else {
   console.error("Form ID not found.");
  }
 };

 const delete_users = async () => {
  for (let formId of deleted_users) {
   try {
    const response = await fetch(process.env.REACT_APP_API_URL + "/api/form/delete/" + formId, {
     method: "DELETE",
    });

    if (response.ok) {
     // If the response is OK but doesn't contain JSON (e.g., status 204 No Content)
     const result = await response.text(); // Attempt to get the text response
     if (result) {
      // Only parse JSON if result is not empty
      try {
       const jsonResult = JSON.parse(result);
       console.log("Delete result:", jsonResult);
      } catch (jsonError) {
       console.log("Response is not JSON:", result);
      }
     }
     // Reset the deleted_users set and update the forms list
     setDeletedUsers(new Set());
     await getFormsList();
     navigate("/Forms");
    } else {
     console.error("Error during deletion:", response.statusText);
    }
   } catch (error) {
    console.error("Error deleting user:", error);
   }
  }
 };

 return (
    <div className="nexus-table-primary">
      <div className="options_container d-flex align-items-center justify-content-end gap-2">
        {deleted_users.size > 0 ? (
          <div onClick={delete_users} className="btn btn-danger">
            Delete
          </div>
        ) : (
          ""
        )}
        <button className="btn btn-primary" onClick={createForm}>
          + Create Form
        </button>
      </div>
  
      {/* Table taking full width */}
      
        <table className="nexus-table-primary" >
          <thead>
            <tr>
              <th>Name</th>
              <th>Firm</th>
              <th>Field Names</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {forms.length > 0 ? (
              forms.map((form) => (
                <tr key={form._id}>
                  <td>{form.name || "N/A"}</td>
                  <td>{form.client_id?.firm_name || "N/A"}</td>
                  <td>
                    {Array.isArray(form.fields) && form.fields.length > 0
                      ? form.fields.map((field) => field.field_name).join(", ")
                      : "No fields available"}
                  </td>
                  <td>{form.status === 1 ? "Active" : "Inactive"}</td>
                  <td>
                    <button className="btn btn-primary" onClick={() => editForm(form._id)}>
                      <img className="edit_icon" src={edit_icon} height="20" alt="Edit" />
                    </button>
                    <button className="btn btn-danger" value={form._id} onClick={deleteProfile}>
                      <img className="delete_icon" src={delete_icon} height="20" alt="Delete" />
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center">No Forms Available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    
  );
  
}
