import * as React from 'react';
import logo from '../../assets/NexusIQ-FinalVersion.jpg';
import SidebarElement from '../Sidebar_Element/sidebar_element';
import './sidebar.css'
import { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';


function Sidebar(props){
  const selectedMenuItem = window.location.pathname;
  const [sidebarElementsArray, setSidebarElements]= useState([
    {availability:true,name:'Dashboards',selected: selectedMenuItem === '/dashboards',key: 0, navigate_url:'dashboards',default_icon_name:'dashboard_icon_default.svg', selected_icon_name: 'dashboard_icon_selected.svg'},
    {availability: false,passKeys: ['superAdmin'],key: 1, name:'Company / Firms', selected: selectedMenuItem === '/firms', navigate_url:'firms', default_icon_name:'company_icon_default.svg', selected_icon_name: 'company_icon_selected.svg'},
    {availability:false,passKeys: ['superAdmin','admin'], name:'Users',key: 2, selected: selectedMenuItem === '/user', navigate_url:'user', default_icon_name:'user_icon_default.svg', selected_icon_name: 'user_icon_selected.svg'},
    {availability: false,passKeys: ['superAdmin','admin'],key: 3, name:'Roles',selected: selectedMenuItem === '/roles', navigate_url:'roles', default_icon_name:'roles_icon_default.svg', selected_icon_name: 'roles_icon_selected.svg'},
    {availability:false,passKeys: ['superAdmin', 'admin'],key: 4, name:'Permissions', selected: selectedMenuItem === '/permissions', navigate_url:'permissions', default_icon_name:'permissions_icon_default.svg', selected_icon_name: 'permissions_icon_selected.svg'},
    {availability:false,passKeys: ['superAdmin', 'admin'],key: 5, name:'User Groups', selected: selectedMenuItem === '/groups', navigate_url:'groups', default_icon_name:'group_icon_default.svg', selected_icon_name: 'group_icon_selected.svg'},
    {availability:false,passKeys: ['superAdmin', 'admin'],key: 6, name:'Email Services', selected: selectedMenuItem === '/EmailServices', navigate_url:'EmailServices', default_icon_name:'group_icon_default.svg', selected_icon_name: 'group_icon_selected.svg'},
    {availability:false,passKeys: ['superAdmin', 'admin'],key: 7, name:'Email Templates', selected: selectedMenuItem === '/EmailTemplates', navigate_url:'EmailTemplates', default_icon_name:'group_icon_default.svg', selected_icon_name: 'group_icon_selected.svg'},
      
  ])
  const navigate = useNavigate();

  const checkavailability= () => {
    console.log("checking")
  }
  const select_page = (key)=>{
    let result=sidebarElementsArray;
    let selected_route="";
    for( let element of result){
      if(element.key !== key){
        element.selected = false;
      }
      else{
        element.selected = true;
        selected_route = element.navigate_url;
      }
    }
    setSidebarElements(result);    
    // window.location.href=`/${selected_route}`
    navigate(`/${selected_route}`)
  }

  return (
    <div className="sidebar_container d-flex flex-column shadow-sm">
      <div className="d-flex flex-column align-items-stretch justify-content-center p-5" style={{height:'10rem'}}><img src={logo} height="50"/></div>
      <div className="sidebar_element_container justify-content-center h-100 p-2 pt-5">
          { 
            sidebarElementsArray.map((sidebar_element,index)=>{
              console.log(props?.user?.user_type)
              return (sidebar_element.availability === true || sidebar_element.passKeys.indexOf(props?.user?.user_type ) > -1)?
                        <div key={index} onClick={()=>{select_page(sidebar_element.key)}}><SidebarElement element={sidebar_element}/></div> 
                          : 
                            ""
            })
          }
          <div onClick={props.logout}><SidebarElement className="logout" element={{name:'Logout',selected:false, default_icon_name:'logout.svg',}} /></div>
      </div>
     
    </div>
  );
}
export default Sidebar;