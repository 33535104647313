// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1vw;
  overflow-y: auto;

}
.bg-primary {
  background-color: red !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
::-webkit-scrollbar {
  width: 5px;  
}
  
::-webkit-scrollbar-track {
-webkit-box-shadow: inset 0 0 6px rgba(0, 138, 197, 0.3);
    margin:1rem;
}

::-webkit-scrollbar-thumb {
  background-color:rgb(0, 89, 233);
  border-radius: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;EAClC,cAAc;EACd,gBAAgB;;AAElB;AACA;EACE,gCAAgC;AAClC;;AAEA;EACE;aACW;AACb;AACA;EACE,UAAU;AACZ;;AAEA;AACA,wDAAwD;IACpD,WAAW;AACf;;AAEA;EACE,gCAAgC;EAChC,mBAAmB;AACrB","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  font-size: 1vw;\n  overflow-y: auto;\n\n}\n.bg-primary {\n  background-color: red !important;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n::-webkit-scrollbar {\n  width: 5px;  \n}\n  \n::-webkit-scrollbar-track {\n-webkit-box-shadow: inset 0 0 6px rgba(0, 138, 197, 0.3);\n    margin:1rem;\n}\n\n::-webkit-scrollbar-thumb {\n  background-color:rgb(0, 89, 233);\n  border-radius: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
