import './groups.css';
import { useEffect, useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import logo from '../../assets/NexusIQ-FinalVersion.jpg';
import edit_icon from '../../assets/edit_icon.svg';
import delete_icon from '../../assets/delete_icon.svg';
import cancel_icon from '../../assets/close_icon.svg';
import Cookies from 'js-cookie';
import Context from '../../user_details';

const group_create_form = {
    name: "",
    description: "",
    created_by: "",
    firm_id: "",
    users_assigned: []
}

const group_update_form = {
    name: "",
    description: "",
    firm_id: "",
    updated_by: { updated_by_id: await Cookies.get("nexus_user_id") },
    users_assigned: []
}


export default function Permissions(props) {
    const [users, setUsers] = useState([]);
    const [users_list, setUsersList] = useState([]);
    const [deleted_groups, setDeletedGroups] = useState(new Set());
    const [permissions, setPermissions] = useState([]);
    const [selected_group_users, setSelectedGroupUsers] = useState([])
    const navigate = useNavigate();
    const [groupsLoader, setGroupsLoader] = useState(true);
    const [FormData, setFormData] = useState(group_create_form);
    const userDetails = useContext(Context)
    const [groupsList, setGroupsList] = useState([]);
    const [firms, setFirms] = useState([]);
    const [available_users, setAvaialableUsers] = useState([]);
    const [newGroupSelectedUsers, setNewGroupSelectedUsers] = useState([]);

    useEffect(() => {
        if (userDetails.user.user_type == 'admin' && available_users.length === 0) {
            group_create_form.firm_id = userDetails.user.firm_id;
            getAvailableUsers(userDetails.user.firm_id);
        }
        if (groupsList.length === 0) {
            group_create_form.created_by = userDetails.user._id;
            setFormData({ ...FormData, created_by: userDetails.user._id })
            get_groups_details();
        }
    }, []);

    useEffect(() => {
        console.log("groups --------------", groupsList, "\n========================roles")
    }, [groupsList]);

    useEffect(() => {
        console.log("===============FormData===============\n", FormData, "\n===============update form data============")

    }, [FormData]);

    useEffect(() => {
        console.log("\n===============create form data============", group_create_form)
    }, [group_create_form]);

    useEffect(() => {
        console.log("\n===========selected Group users==========\n", selected_group_users)
    }, [selected_group_users]);


    useEffect(() => {
        console.log("\n===========selected new  Group users==========\n", newGroupSelectedUsers)
    }, [newGroupSelectedUsers]);

    const getAvailableUsers = async (firmId) => {
        await fetch(process.env.REACT_APP_API_URL + "/api/admin/list/readByFirm/" + firmId)
            .then(async result => {
                const res = await result.json();
                setAvaialableUsers(res.result);
            })
    }
    const get_groups_details = async () => {
        setGroupsLoader(true);
        await fetch(process.env.REACT_APP_API_URL + "/api/groups/list?type=" + userDetails.user.user_type + "&firm_id=" + userDetails.user.firm_id)
            .then(async res => {
                const result = await res.json();
                setGroupsList(result);
                setGroupsLoader(false);
                console.log(result, "this is groups list");
            })
            .catch(err => {
                console.log(err)
            })
    }

    const changeSelectedGroupUsers = (user) => {
        let temporary_users = selected_group_users;
        let index = temporary_users.indexOf(user);
        if (index > -1) {
            temporary_users.splice(index, 1);
            setNewGroupSelectedUsers([...temporary_users]);
        }
        else {
            temporary_users.push(user);
            setNewGroupSelectedUsers([...temporary_users]);
        }
    }

    const getFirms = async () => {
        const firmsAvailable = await fetch(process.env.REACT_APP_API_URL + "/api/firms/list")
            .then(async res => {
                const result = await res.json()
                setFirms(result.results)
            })
    }

    const Search_user = (event) => {
        let x = users.filter(element => {
            let { username } = element;
            let value = event.target.value.trim()
            if (username.indexOf(value) >= 0) {
                return element
            }
        })
        setUsersList(x);
    }

    const deleteSelectedGroups = async (event) => {
        let del_users = deleted_groups;
        var selected_user = event.target;
        var delete_user_id = event.target.parentNode.getAttribute("value");
        if (selected_user.tagName !== 'IMG') {
            selected_user = selected_user.childNodes[0];
            delete_user_id = event.target.getAttribute("value");
        }
        if (selected_user.className === "delete_icon") {
            selected_user.src = cancel_icon;
            selected_user.className = "cancel_icon";
            del_users.add(delete_user_id);

        }
        else {
            selected_user.src = delete_icon;
            selected_user.className = "delete_icon";
            if (del_users.has(delete_user_id)) del_users.delete(delete_user_id)
        }
        setDeletedGroups(del_users);
        navigate("/groups");
        console.log("this is deleted permissions,", deleted_groups)
    }


    const deleteSelectedPermissions = async () => {
        var confirmDeleteButton = document.getElementById("confirmDeleteButton");
        confirmDeleteButton.click();
    }

    const rolenames = async () => {
        setGroupsLoader(true)
        const result = await fetch(process.env.REACT_APP_API_URL + "/api/permissions/list?type=" + userDetails.user.user_type + "&firm_id=" + userDetails.user.firm_id + `&getFirmDetails =${true}`)
            .then(async (res) => {

                const r = await res.json()
                setGroupsList(r)
                setGroupsLoader(false)
                return r
            })


        console.log("these are roles", result)
    }
    const updateGroup = async (groupId) => {
        const { name, description } = FormData;
        const { updated_by } = group_update_form;
        const result = await fetch(process.env.REACT_APP_API_URL + "/api/groups/update/" + groupId, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ name, description, users_assigned: selected_group_users, updated_by })
        })
            .then(async res => {
                // rolenames();
                get_groups_details();
                return await res.json()

            })
            .catch(err => {
                console.log(err)
            })
        console.log(result);
        navigate("/groups");

    }
    const createGroup = async () => {
        const { name, description, firm_id, created_by } = FormData;
        if (name.length > 0 && description.length > 0 && firm_id.length > 0) {
            const result = await fetch(process.env.REACT_APP_API_URL + "/api/groups/create", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ name, description, firm_id, created_by, users_assigned: newGroupSelectedUsers })
            })
                .then(async (res) => {
                    return await res.json();

                })

            if (result.success) {
                document.getElementById("closeCreateModal").click();
                get_groups_details();
            }
            else {
                alert("Server Error")
            }
        }
        else {
            alert("Missing required Fields");
        }

    }
    useEffect(() => {
        setFormData({ ...FormData, permissions_ids: selected_group_users });
        return setFormData({ ...FormData, permissions_ids: selected_group_users });
    }, [selected_group_users])

    const group_data = (event) => {
        setFormData({ ...FormData, [event.target.name]: event.target.value })

    }

    const confirmDeleteGroups = async () => {
        let deletedGroups = Array.from(deleted_groups);
        await new Promise((resolve, reject) => {
            deletedGroups.forEach(async (groupId, index) => {
                await fetch(process.env.REACT_APP_API_URL + "/api/groups/delete/" + groupId, { method: 'DELETE' })
                    .then()
                    .catch(() => {
                        get_groups_details();
                        navigate("/groups");
                    })
                if (index == deletedGroups.length - 1) {
                    resolve();
                }
            })
        })
            .then(() => {
                get_groups_details();
                navigate("/groups");
            })
    }
    const newFormData = async () => {
        setNewGroupSelectedUsers([]);
        getFirms();
        setFormData({ ...group_create_form, created_by: userDetails.user._id });
        if (userDetails.user.user_type === 'superAdmin') {
            setFormData({ ...FormData, firm_id: "" })
        }
        document.getElementById("recipient-name").value = "";
        document.getElementById("message-text").value = "";
        if (userDetails.user.user_type === 'superAdmin') {
            document.getElementById("new-firm-select").value = "";
            setAvaialableUsers([]);
        }
    }

    const updateFormData = (selectedGroup) => {
        var name = document.getElementById(`${selectedGroup.group._id}_name`)
        var description = document.getElementById(`${selectedGroup.group._id}_description`)
        name.value = selectedGroup.group.name;
        description.value = selectedGroup.group.description;
        setSelectedGroupUsers([...selectedGroup.group.users_assigned]);
        setFormData({ ...FormData, name: selectedGroup.group.name, description: selectedGroup.group.description, firm: selectedGroup.group.firm_id })
    }

    const cancelDeletePermissions = () => {
        console.log("cancelling")
        var close_button = document.getElementsByClassName("cancel_icon");
        console.log(close_button[0], "cancelling");
        for (let i = 0; i < close_button.length; i++) {
            close_button[i].className = "delete_icon";
            close_button.src = delete_icon
        }
        get_groups_details();
        setDeletedGroups(new Set());
    }

    const checkboxActionUser = (userId) => {
        var index = selected_group_users.indexOf(userId);
        console.log("checkbox action", index)
        let temporary_selected_users = selected_group_users;
        if (index > -1) {
            temporary_selected_users.splice(index, 1);
            console.log(temporary_selected_users)
            setSelectedGroupUsers([...temporary_selected_users]);
        }
        else {
            temporary_selected_users.push(userId);
            console.log(temporary_selected_users)
            setSelectedGroupUsers([...temporary_selected_users]);
        }
    }
    const newGroupUsersChange = (userId) => {
        let temporary_users = newGroupSelectedUsers;
        let index = temporary_users.indexOf(userId);
        if (index > -1) {
            temporary_users.splice(index, 1);
            setNewGroupSelectedUsers([...temporary_users]);
        }
        else {
            temporary_users.push(userId);
            setNewGroupSelectedUsers([...temporary_users]);
        }
    }

    if (!userDetails) {
        return (<div>Loading ........</div>)
    }

    else {
        group_update_form.updated_by.updated_by_id = userDetails.user._id;
        if (userDetails.user.user_type !== 'superAdmin') group_create_form.created_by = userDetails.user.firm_id;
        return (
            <Context.Consumer>
                {value => {
                    const { user } = value;
                    return (
                        <div className="user_page ">
                            {/*------------------Confirm Delete Permissions Modal */}

                            <button type="button" className="btn btn-primary d-none" id="confirmDeleteButton" data-bs-toggle="modal" data-bs-target="#confirmDeleteGroups">
                                Launch demo modal
                            </button>

                            <div className="modal fade" id="confirmDeleteGroups" tabIndex="-1" role="dialog" aria-labelledby="confirmDeleteGroupsTitle" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <img src={logo} height="40" />
                                        </div>
                                        <div className="modal-body text-secondary fs-6">
                                            Do you wish to proceed?
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={cancelDeletePermissions}>Cancel</button>
                                            <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => { confirmDeleteGroups() }}>Delete Anyway</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*--------------------------------------------------- */}
                            {/* -----------------------------------------------new permission create modal fade----------------------------------- */}
                            <div className="modal fade" id="newrole" tabIndex="-1" aria-labelledby="newroleLabel" aria-hidden="true" >
                                <div className="modal-dialog" >
                                    <div className="modal-content" style={{ width: "35rem" }}>
                                        <div className="modal-header">
                                            <h5 className="modal-title text-secondary" id="newroleLabel">New Group</h5>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div className="modal-body">
                                            <form>
                                                <div className="mb-3">
                                                    <label htmlFor="recipient-name" className="col-form-label" >Group Name</label>
                                                    <input type="text" className="form-control" onChange={group_data} name='name' id="recipient-name" />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="message-text" className="col-form-label" >Description</label>
                                                    <textarea className="form-control" id="message-text" onChange={group_data} name='description'></textarea>
                                                </div>
                                                {userDetails.user.user_type == 'superAdmin' ?
                                                    <div className="d-flex flex-column mb-3">
                                                        <span className="text-primary fs-4">Firm</span>
                                                        <br />
                                                        <select className='fs-5' id="new-firm-select" onChange={(event) => { getAvailableUsers(event.target.value); setFormData({ ...FormData, firm_id: event.target.value }) }}>
                                                            <option value="" >---select a firm---</option>
                                                            {
                                                                firms.map((firm, index) => {
                                                                    return <option key={index} value={firm._id}>{firm.firm_name}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    : ""}
                                                <div className=' role_card_permissions'>
                                                    <p className=' text-success fs-5 fw-bolder mt-3 m-1'>Assigned Users</p>
                                                    <div className='d-flex flex-row flex-wrap justify-content-start gap-4 align-content-start role_card_permissions_container p-1'>
                                                        {
                                                            available_users.map((user, index) => {
                                                                return (
                                                                    <div key={index} className='d-flex gap-1 align-items-center- justify-content-center'>
                                                                        <input type="checkbox" checked={newGroupSelectedUsers.indexOf(user._id) > -1} onChange={() => { }} onClick={() => { newGroupUsersChange(user._id) }} />
                                                                        <p className='m-0'>{user.first_name} {user.last_name}</p>
                                                                    </div>)
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id="closeCreateModal">Close</button>
                                            <button type="button" className="btn btn-primary" onClick={createGroup}>Create</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* ------------------------------------------------------*/}
                            <div className="options_container d-flex align-items-center justify-content-end gap-2">
                                {deleted_groups.size > 0 ? <div onClick={deleteSelectedPermissions} className="btn btn-danger">Delete</div> : ""}
                                <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#newrole" data-bs-whatever="@mdo" onClick={newFormData}>+ New Group</button>
                            </div>
                            <div className="user_cards_container">
                                <div className=" ">
                                    <div className="user_detail_card_headings  text-white p-2 bg-primary align-items-center d-flex justify-content-around">
                                        <span className={"fw-bold " + (userDetails?.user?.user_type == 'superAdmin' ? "user_detail_field_superAdmin" : "user_detail_field")}>Group Name</span>
                                        {userDetails && userDetails?.user?.user_type === 'superAdmin' ? <div className={"fw-bold " + (userDetails?.user?.user_type == 'superAdmin' ? "user_detail_field_superAdmin" : "user_detail_field")}>Firm</div> : ""}
                                        <span className={"fw-bold " + (userDetails?.user?.user_type == 'superAdmin' ? "user_detail_field_superAdmin" : "user_detail_field")}>Description</span>
                                        <span className={"fw-bold " + (userDetails?.user?.user_type == 'superAdmin' ? "user_detail_field_superAdmin" : "user_detail_field")}></span>
                                        <div className="user_detail_field d-flex align-items-center fw-light text-primary user_search_input_container bg-warning">
                                            <img height="60%" style={{ position: "absolute", right: '2px', zIndex: 1, }} src={require("../../assets/search_icon.svg").default} />
                                            <input className=" fw-light text-primary" style={{ textAlign: "center", fontSize: '0.7rem', width: '100%', paddingRight: '15px' }} type="" placeholder="Search Permission" onChange={Search_user} />
                                        </div>
                                    </div>
                                    {groupsLoader ?
                                        <div className="h-100 w-100 d-flex justify-content-center bg-white align-items-center">
                                            <div className="text-center text-primary fw-bolder fs-2">
                                                <div className="spinner-border" role="status"></div>
                                            </div>
                                        </div> :
                                        <div className="user_details_table">
                                            {
                                                groupsList.length === 0 ?
                                                    <div style={{ height: "90%" }} className="no_user bg-white d-flex flex-column justify-content-center align-items-center">
                                                        <img height="50%" src={require("../../assets/user_not_found.svg").default} alt="user not found" />
                                                        <p className="fw-bold">No Groups Found</p>
                                                    </div> :
                                                    <div>
                                                        {groupsList.map((role, index) => {
                                                            return (
                                                                <div key={index} className="user_detail_card bg-white p-2 d-flex justify-content-around align-items-center">
                                                                    <span className="user_detail_field fw-bold">{role.group?.name}</span>
                                                                    {userDetails && userDetails?.user?.user_type === 'superAdmin' ? <div className={"fw-bold " + (userDetails?.user?.user_type == 'superAdmin' ? "user_detail_field_superAdmin" : "user_detail_field")}>{role.firm?.firm_name} </div> : ""}
                                                                    <span className="user_detail_field">{role.group.description}</span>
                                                                    <div className="user_detail_field   d-flex gap-1 justify-content-center align-items-center" style={{ height: "100%" }}>
                                                                        <div className="btn btn-primary update_button" data-bs-toggle="modal" data-bs-target={"#updaterole" + `${role.group._id}`} onClick={() => { updateFormData(role) }} data-whatever="@getbootstrap" >
                                                                            View & Update
                                                                        </div>
                                                                        {/* --------------------------------------------------------------update permission modal fade--------------------------------------------------------------------------*/}
                                                                        <div className="modal fade h-100" id={"updaterole" + role.group._id} tabIndex="-1" aria-labelledby="updateGroupLabel" aria-hidden="true" >
                                                                            <div className="modal-dialog " >
                                                                                <div className="modal-content" style={{ width: "35rem" }}>
                                                                                    <div className="modal-header">
                                                                                        <h5 className="modal-title text-secondary " id="updateGroupLabel">Update Group</h5>
                                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setSelectedGroupUsers([]) }}></button>
                                                                                    </div>
                                                                                    <div className="modal-body">
                                                                                        <form>
                                                                                            <div className="mb-3  text-align-left">
                                                                                                <label htmlFor="recipient-name " className="col-form-label fw-bolder text-primary text-align-left" >Group Name</label>
                                                                                                <input type="text" className="form-control" onChange={group_data} name='name' id={role.group._id + "_name"} onClick={(event) => { console.log(event.target) }} />
                                                                                            </div>
                                                                                            <div className="mb-3">
                                                                                                <label htmlFor="message-text" className="col-form-label fw-bolder text-primary" >Description</label>
                                                                                                <textarea className="form-control" id={role.group?._id + "_description"} onChange={group_data} name='description' ></textarea>
                                                                                            </div>
                                                                                            <div className=' role_card_permissions'>
                                                                                                <p className=' text-success fs-5 fw-bolder mt-3 m-1'>Assigned Users</p>
                                                                                                <div className='d-flex flex-row flex-wrap justify-content-start align-content-start role_card_permissions_container p-1'>
                                                                                                    {
                                                                                                        role.usersAvailable.map((user, index) => {
                                                                                                            return (
                                                                                                                <div key={index} className='d-inline-flex align-items-center p-3'>
                                                                                                                    <input type='checkbox' checked={selected_group_users.indexOf(user._id) > -1} onClick={() => { checkboxActionUser(user._id) }} onChange={() => { }} />
                                                                                                                    <span>&nbsp;&nbsp;{user.first_name} {user.last_name}</span>
                                                                                                                </div>
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </div>

                                                                                            </div>
                                                                                        </form>
                                                                                    </div>
                                                                                    <div className="modal-footer">
                                                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id="closeCreateModal" onClick={() => { setSelectedGroupUsers([]) }}>Close</button>
                                                                                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => { updateGroup(role.group?._id) }}>update</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {/*------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
                                                                    </div>
                                                                    <div className="user_detail_field">
                                                                        <div className="btn btn-danger" value={role.group?._id} id={role.group._id} onClick={deleteSelectedGroups}>
                                                                            <img className="delete_icon" src={delete_icon} height="20" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    )
                }}
            </Context.Consumer>
        )
    }
}
