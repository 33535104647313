// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.emailTemplatesContainer .modal-dialog {
  display: flex;
  --bs-modal-width: 70vw !important;
}
.emailTemplatesContainer .modal-content {
  height: 95vh !important;
  overflow-y: scroll;
}

.offcanvas {
  z-index: 1056;
  overflow-y: auto;
}

td {
  border: 1px solid black;
  padding: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/EmailTemplates/emailTemplate.scss"],"names":[],"mappings":"AACI;EACG,aAAA;EACC,iCAAA;AAAR;AAGI;EACI,uBAAA;EACA,kBAAA;AADR;;AAIA;EACI,aAAA;EACA,gBAAA;AADJ;;AAGC;EACG,uBAAA;EACA,aAAA;AAAJ","sourcesContent":[".emailTemplatesContainer  .modal {\n    &-dialog {\n       display: flex;\n        --bs-modal-width: 70vw !important;\n    }\n    \n    &-content {\n        height: 95vh !important;\n        overflow-y:scroll;\n    }\n}\n.offcanvas {\n    z-index: 1056;\n    overflow-y:auto;\n}\n td {\n    border: 1px solid black;\n    padding:1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
