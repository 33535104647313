import './firms.css'
import {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import edit_icon from '../../assets/edit_icon.svg';
import delete_icon from '../../assets/delete_icon.svg';
import cancel_icon from '../../assets/close_icon.svg';
export default function Firms(props){
    const [firms,setFirms] = useState([]);
    const [firms_list, setFirmsList] = useState([]);
    const [deleted_firms, setDeletedFirms] = useState(new Set());
    const navigate= useNavigate();
    useEffect(()=>{
        get_firms_list();
        return ;
    },[]);
    useEffect(()=>{
        console.log("====================\n", firms, "\n========================")
    },[firms])
   
    const get_firms_list = async()=>{
     const firmsList =  await fetch(process.env.REACT_APP_API_URL+"/api/firms/list")
     .then(async res=>{
        return await res.json();
     })
     .catch(err=>{
         console.log(err)
     })
     console.log(firmsList,"this is firms list")
     if(firmsList.success){
        setFirmsList(firmsList.results);
        setFirms(firmsList.results);
     }
     
    }
 
    const CreateFirm = ()=>{
     navigate("/create-firm")
    }
    const UpdateFirm = (firmId)=>{
     
     navigate(`/update-firm?firmId=${firmId}`)
    }
    const Search_Firm=(event)=>{     
        let x= firms.filter(element=>{
           return element.firm_name.indexOf(event.target.value) >= 0
        })
        setFirmsList(x);    }
    const deleteFirm = async(event) => {
        let del_firms = deleted_firms;
        var selected_firm = event.target;
        var delete_firm_id = event.target.parentNode.getAttribute("value");
        if(selected_firm.tagName !== 'IMG'){
            selected_firm = selected_firm.childNodes[0];
            delete_firm_id = event.target.getAttribute("value");
        }
        if ( selected_firm.className === "delete_icon" ){
            selected_firm.src = cancel_icon;
            selected_firm.className = "cancel_icon";        
            del_firms.add(delete_firm_id);
           
        }
        else{
            selected_firm.src = delete_icon;
            selected_firm.className = "delete_icon";
            if(del_firms.has(delete_firm_id)) del_firms.delete(delete_firm_id)
        }
        await setDeletedFirms(del_firms);
        navigate("/firms");
       }
    
       const delete_firms = async()=>{
        for(let firm of deleted_firms){
           var result = await fetch(process.env.REACT_APP_API_URL+"/api/firms/delete/"+firm,{
            method: "DELETE"        
           })
           .then(async res => {
            return await res.json()
           })
           console.log(result);
           setDeletedFirms(new Set());
           await get_firms_list();
           navigate("/firms")
        }
       }
    return( 
        <div className="user_page h-100 w-100">
            
            <div className="options_container d-flex align-items-center justify-content-end gap-2">   
                { deleted_firms.size  > 0 ? <div onClick={delete_firms} className="btn btn-danger">Delete</div> : ""}      
                <button className="btn btn-primary" onClick={CreateFirm}>+ New Firm</button>   
                          
            </div>
            
            <div className="user_cards_container">
              <div className="bg-primary ">
                    <div className="user_detail_card_headings  text-white p-2 bg-primary align-items-center d-flex justify-content-around">
                            <span className="user_detail_field fw-bold">Name</span>
                            <span className="user_detail_field fw-bold">Contact No</span>
                            <span className="user_detail_field fw-bold">Email</span>
                            <span className="user_detail_field fw-bold">Address</span>
                            <div className="user_detail_field d-flex align-items-center fw-light text-primary user_search_input_container bg-warning">
                                <img  height="60%" style={{position:"absolute",right:'2px',zIndex:1,}} src={require("../../assets/search_icon.svg").default}/>
                                <input className=" fw-light text-primary" style={{textAlign:"center",fontSize:'0.7rem',width:'100%',paddingRight:'15px'}} type="" placeholder="Search Firm" onChange={Search_Firm}/>
                            </div>
                        
                    </div>
                    <div className="user_details_table bg-white">
                        {firms_list.length === 0? 
                                                <div style={{height:"90%"}} className="no_user bg-white d-flex flex-column justify-content-center align-items-center">
                                                    <img height="50%" src={require("../../assets/user_not_found.svg").default} alt="user not found" />
                                                    <p className="fw-bold">No Firms Found</p>
                                              </div>:
                                                    <div> 
                                                      {firms_list.map((firm,index)=>{
                                                            return (
                                                                <div key={index} className="user_detail_card bg-white d-flex p-2 justify-content-around align-items-center">
                                                                    <span className="user_detail_field fw-bold">{firm.firm_name}</span>
                                                                    <span className="user_detail_field">{firm.contact_number}</span>
                                                                    <span className="user_detail_field">{firm.contact_email}</span>
                                                                    <span className="user_detail_field">{firm.address}</span>
                                                                    <div className="user_detail_field d-flex gap-1 align-items-center justify-content-center">
                                                                        <button className="btn btn-primary update_button" onClick={()=>{ UpdateFirm(firm._id)}}>
                                                                            <img className="edit_icon" src={edit_icon} height="20"/>
                                                                        </button>
                                                                        <div className="btn btn-danger" value={firm._id} id={firm._id} onClick={deleteFirm}>
                                                                            <img className="delete_icon" src={delete_icon} height="20"/>
                                                                        </div>
                                                                    </div>
                                                                </div>                                        
                                                            )                                
                                                        })}
                                                    </div>
                        }
                       
                    </div>
                </div>               
    
               
                
            </div>
        </div>
        )
}
// {firms_list.map((firm,index)=>{
//     return (
//         <div key={index} className="user_detail_card bg-white d-flex p-2 justify-content-around align-items-center">
//             <span className="user_detail_field fw-bold">{firm.username}</span>
//             <span className="user_detail_field">{firm.user_type}</span>
//             <span className="user_detail_field">{firm.email}</span>
//             <span className="user_detail_field">{firm.status}</span>
//             <div className="user_detail_field"><button className="btn btn-primary update_button" onClick={()=>{ UpdateFirm(firm._id)}}>Update</button></div>
//         </div>                                        
//     )                                
// })}