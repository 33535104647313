import { useEffect } from "react";
import './dashboard.css';
import {
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
} from "recharts";
import { PieChart, Pie, Legend, Cell, ResponsiveContainer } from "recharts";
import userDetailsContext from "../../user_details";

function DashBoard(){
    const data = [
      { name: "Jan 2024", students: 400 },
      { name: "Feb 2024", students: 700 },
      { name: "March 2024", students: 900 },
     
    ];
    const DataFormatter = (number) => {
      if (number > 1000) {
        return `${(number / 1000).toString()}k`
      }
      return number.toString()
    }
    useEffect(()=>{
      if(window.location.pathname !== '/dashboards' && window.location.pathname !== '/') window.location.href='/dashboards'
      return;
    },[])

    const PieData = [
      {
        count: 809680,
        language: "Telugu",
      },
      {
        count: 4555697,
        language: "Hindi",
      },
      {
        count: 12345657,
        language: "English",
      },
    ]
    const renderColorfulLegendText = (value, entry) => {
      return (
        <span style={{ color: "#596579", fontWeight: 500, padding: "10px" }}>
          {value}
        </span>
      );
    };

    return (
    <div className="dashboards_container d-flex align-items-stretch justify-content-center align-items-center">
         <div className="option_cards_container d-flex flex-column  justify-content-around ">
             <div className="option_card sales_card  d-flex flex-column justify-content-center align-items-center gap-3 text-center">                
                    <img src={require("../../assets/dashboards/sales_icon.svg").default} className="" alt="sales_icon" height="30"/>
                    <p>Sales</p>
              </div>
             <div className="option_card customers_card d-flex flex-column justify-content-center align-items-center gap-3  text-center">
                    <img src={require("../../assets/dashboards/customers_icon.svg").default} alt="sales_icon" height="30"/>
                    <p>Customers</p>
                                 
             </div>
             <div className="option_card tasks_card d-flex flex-column justify-content-center align-items-center gap-3  text-center">
                    <img src={require("../../assets/dashboards/tasks_icon.svg").default} alt="sales_icon" height="30"/>
                    <p>Tasks</p>
                    
             </div>
             <div className="option_card profits_card d-flex flex-column justify-content-center align-items-center gap-3   text-center">
                    <img src={require("../../assets/dashboards/profit_icon.svg").default} alt="sales_icon" height="30"/>
                    <p>Profit</p>
 
             </div>
        </div>
        
        <div className="graph_container d-flex flex-column fw-bold ">            
            <div className="bar_graph d-flex flex-column align-items-center justify-content-center">
                <p className="mt-3">Website Visits</p>
                <ResponsiveContainer className="graph_res d-flex">
                    <BarChart data={data} style={{cursor:'pointer'}}>
                      <Bar dataKey="students" fill="white" />
                          <XAxis dataKey="name" stroke="white"/>
                          <YAxis stroke="white"/>                      
                    </BarChart>
                </ResponsiveContainer>
            </div>
            <div className="chart p-1 h-100 d-flex flex-column justify-content-center align-items-center ">
                <p>Profits</p>
                <ResponsiveContainer className="rChart d-flex flex-column jusitfy-content-between align-items-center pr-3 pl-3">
                  <PieChart margin={{left:100}}>
                    <Pie                   
                      data={PieData}
                      startAngle={0}
                      endAngle={360}
                      innerRadius="40%"
                      outerRadius="90%"
                      dataKey="count"
                    >
                      <Cell name="Jan 2024" fill="orange" />
                      <Cell name="Feb 2024" fill="purple" />
                      <Cell name="Mar 2024" fill="orangered" />
                    </Pie>
                    <Legend
                      iconType="circle"
                      layout="vertical"
                      verticalAlign="middle"
                      align="right"
                    />
                  </PieChart>
                </ResponsiveContainer>              
            </div>
        </div>
     </div>
    )
}
export default DashBoard;