// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.permissions_page{
    height: 100%;
    width:100%;
    font-size:1vw;
    padding:2.8rem ;
    padding-top:0px;
    padding-bottom:0.5rem;
}
.permission_field{
    width:33% !important;
}
.permission_field_superAdmin{
    background-color: red;
}`, "",{"version":3,"sources":["webpack://./src/components/Permissions/permissions.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,UAAU;IACV,aAAa;IACb,eAAe;IACf,eAAe;IACf,qBAAqB;AACzB;AACA;IACI,oBAAoB;AACxB;AACA;IACI,qBAAqB;AACzB","sourcesContent":[".permissions_page{\n    height: 100%;\n    width:100%;\n    font-size:1vw;\n    padding:2.8rem ;\n    padding-top:0px;\n    padding-bottom:0.5rem;\n}\n.permission_field{\n    width:33% !important;\n}\n.permission_field_superAdmin{\n    background-color: red;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
