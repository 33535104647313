import {useState,useEffect, useContext} from 'react';
import Cookies from 'js-cookie';
import {useMatch,useNavigate,useSearchParams} from 'react-router-dom';
import Context from '../../user_details';

const firm_update_form={
    firm_name: "",
    contact_name: "",
    contact_email: "",
    contact_number: "",
    address:"",
    status:0,
    updated_by: ""
}
function FirmUpdate( props ){
    const [FormData, setFormData] = useState(firm_update_form);
    const [searchParams] = useSearchParams();
    const {user} = useContext(Context);
    const navigate = useNavigate();
    const target_firm_id = searchParams.get("firmId");
    useEffect(()=>{
        get_updated_by_id();
        fillDetails();
    },[])
    useEffect(()=>{
        console.log("=======================\n",FormData,"\n======================")
    },[FormData])
    const get_updated_by_id = async()=>{
        const updated_by_id = await Cookies.get("nexus_user_id");
        setFormData({...FormData,updated_by:[{updated_by_id}]})
    }
    const updateFirm=async()=>{
        let input_verifier=true;
        for(let object of Object.keys(firm_update_form)){
            if(String(FormData[object]).length === 0){
                input_verifier=false;
                alert(`Please fill all the details to create profile - ${object}`);
                break;
            }
            else{
                console.log("passed", FormData[object]);
            }
        }
        if (input_verifier) {
            const result = await fetch(process.env.REACT_APP_API_URL+"/api/firms/update/"+ target_firm_id,{
                method: "POST",
                headers: {
                    "Content-Type" : "application/json",
                },
                body: JSON.stringify(FormData)
            })
            .then(async res=>{
                const result = await res.json();
                return result;
            })
            if (result.success == true){
                console.log("result uis true for navbigations")
                window.location.href = "/";
            }
        }        
    }
    const fillDetails = async()=>{
        const result = await fetch(process.env.REACT_APP_API_URL+"/api/firms/read/"+target_firm_id)
            .then(async data=>{
                let res = await data.json()
                res = res.result;
                setFormData({...res, updated_by: {updated_by_id:user._id}});
                return res
            })
        console.log("filldetails result",result)
    }
    const InputChange=(event)=>{
        setFormData({...FormData, [event.target.name]: event.target.value})
    }

    return(
        <div className="profile_create_container d-flex flex-column justify-content-center align-items-center w-100">
            <div className='d-flex flex-column align-items-center form_container p-5'>
                <div className='d-flex align-items-center justify-content-center w-100 mb-3'>
                    <img src={require("../../assets/NexusIQ-FinalVersion.jpg")} height="45rem"/>
                </div>
                <div className="d-flex  input_container">
                    <span className='form_label'>FIRM NAME</span>
                    <input  className="input_element form_input_element" type="text" name="firm_name" onChange={InputChange} defaultValue={FormData.firm_name}/>
                </div>
                <div className="d-flex  input_container">
                    <span className='form_label'>CONTACT NAME</span>
                    <input  className="input_element form_input_element" type="text" name="contact_name" onChange={InputChange} defaultValue={FormData.contact_name}/>
                </div>
                <div className="d-flex  input_container">
                    <span className='form_label'>EMAIL</span>
                    <input  className="input_element form_input_element" type="email" name="contact_email" onChange={InputChange} defaultValue={FormData.contact_email}/>
                </div>
                <div className="d-flex input_container">
                    <span className='form_label'>CONTACT NUMBER</span>
                    <input  className="input_element form_input_element" type="text" name="contact_number" onChange={InputChange} defaultValue={FormData.contact_number}/>
                </div>
                <div className="d-flex  input_container">
                    <span className='form_label'>ADDRESS</span>
                    <input  className="input_element form_input_element" type="text" name="address" onChange={InputChange} defaultValue={FormData.address}/>
                </div>
                <div className="d-flex  input_container">
                    <span className='form_label'>STATUS</span>
                    <select className="input_element form_input_element p-1 " name="status" onChange={InputChange}>
                        <option value="0">0</option>
                        <option value="1">1</option>
                    </select>
                </div>
                <input type="button" className="user_create_button mt-4" value="Update Firm" onClick={updateFirm}/>
            </div>

        </div>
    )
}
export default FirmUpdate;