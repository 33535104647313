import './sidebar_element.css'
export default function SidebarElement(props){
    var selected = props.element.selected;
    var selected_page= selected===true?"selected":"";
    var icon_name = selected === true? props.element.selected_icon_name:props.element.default_icon_name;
    return(
        <div className={` d-flex align-items-center gap-3 mb-3 sidebar_element mb-1 w-100 ${selected_page} `}>
          <img src={require(`../../assets/${icon_name}`)} className="sidebar_icons"/><span>{props.element.name}</span>
        </div>
    )
}