import Context from '../../user_details';
import "./roles.css";
import {useEffect, useState, useContext} from 'react';
import { useNavigate } from "react-router-dom";
import edit_icon from '../../assets/edit_icon.svg';
import delete_icon from '../../assets/delete_icon.svg';
import cancel_icon from '../../assets/close_icon.svg';
import Cookies from 'js-cookie';
import logo from '../../assets/NexusIQ-FinalVersion.jpg';

const role_create_form={
    role_name:"",
    description:"",
    permissions_ids:[],
    created_by: await Cookies.get("nexus_user_id"),
    firm_id: ""
}

const role_update_form={
    role_name:"",
    description:"",
    permissions_ids:[],
    firm_id: "",
    updated_by:{updated_by_id: await Cookies.get("nexus_user_id")}
}


export default function Roles(props) {
   const [users,setUsers] = useState([]);
   const [users_list, setUsersList] = useState([]);
   const [deleted_roles, setDeletedRoles] = useState(new Set());
   const [permissions, setPermissions] = useState([]);
   const [selected_permissions, setSelectedPermissions] = useState([])
   const navigate= useNavigate();
   const [rolesLoader,setRolesLoader] = useState(true);
   const [permissionsLoader,setPermissionsLoader] = useState(true);
   const [FormData, setFormData] = useState(role_create_form);
   const userDetails = useContext(Context);
   const [deleteVerifier, setDeleteVerifier] = useState(false);
   const [roles,setRoles]=useState([]);
   const [firms, setFirms] = useState([]);
   useEffect(()=>{
        getFirms();
        role_create_form.firm_id = userDetails.user.firm_id;
        rolenames();
        get_permisiions_details(userDetails.user.firm_id);
        return ;
   },[])
   useEffect(()=>{
    console.log(permissions,"========================\n permissions==============")
   },[permissions])
   useEffect(()=>{
    console.log(firms,"firms")
   },[firms])
   useEffect(()=>{
    console.log(selected_permissions,"selected permissions --------------",roles,"\n========================roles")
   },[selected_permissions,roles])
   useEffect(()=>{
    console.log("===============FormData===============\n",FormData)
   },[FormData])

   const getFirms=async()=>{
    const firmsAvailable = await fetch(process.env.REACT_APP_API_URL+"/api/firms/list")
    .then(async res => {
        const result = await res.json()
        setFirms(result.results)
    })
   }
   const get_users_list = async()=>{
    
    const usersList =  await fetch(process.env.REACT_APP_API_URL+"/api/roles/list?type="+userDetails.user.user_type+"&firm_id="+userDetails.user.firm_id)
    .then(async res=>{
               
               return await res.json();
    })
    .catch(err=>{
        console.log(err)
    })
    console.log(usersList,"this is users list")
    setUsersList(usersList);
    setUsers(usersList);
   }
   const get_permisiions_details = async( firm_id)=>{
    setPermissionsLoader(true)
    const permissionsdetails= await fetch(process.env.REACT_APP_API_URL+"/api/Permissions/readByFirm/"+firm_id)
    .then(async res=>{
       
        return await res.json();
    })
    console.log(permissionsdetails)
    setPermissions(permissionsdetails.result);
    setPermissionsLoader(false)
    
}
   const Search_user=(event)=>{    
       let x= users.filter(element=>{
        let {username} = element;
        let value = event.target.value.trim()
        if(username.indexOf(value) >= 0){
            return element
        }
       })
       setUsersList(x);
   }

   const deleteProfile = async(event) => {
    let del_users = deleted_roles;
    var selected_user = event.target;
    var delete_user_id = event.target.parentNode.getAttribute("value");
    if(selected_user.tagName !== 'IMG'){
        selected_user = selected_user.childNodes[0];
        delete_user_id = event.target.getAttribute("value");
    }
    if ( selected_user.className === "delete_icon" ){
        selected_user.src = cancel_icon;
        selected_user.className = "cancel_icon";        
        del_users.add(delete_user_id);
       
    }
    else{
        selected_user.src = delete_icon;
        selected_user.className = "delete_icon";
        if(del_users.has(delete_user_id)) del_users.delete(delete_user_id)
    }
    setDeletedRoles(del_users);
    navigate("/roles");
    console.log("this is deleted users,",deleted_roles)
   }


   const delete_users = async()=>{
    console.log(deleted_roles,"===============================================deleted roles are===================================")
    let canBeDeleted = true;
    if (deleteVerifier == false)
      canBeDeleted= await fetch(process.env.REACT_APP_API_URL+"/api/canDelete/roles",{
        method: 'POST',
        headers:{
            "Content-Type": "application/json"
        },
        body: JSON.stringify({deleted_roles: Array.from(deleted_roles)})
    })
    const DeletingPermissions = await new Promise((res,rej)=>{
        if(canBeDeleted.success){
            res([])
        }
        else{
            var confirmDeleteButton = document.getElementById("confirmDeleteButton");
            confirmDeleteButton.click();
            rej([])


        }
    }).then(async data=>{
        for(let user of deleted_roles){
            var result = await fetch(process.env.REACT_APP_API_URL+"/api/roles/delete/"+user,{
             method: "POST",            
            })       
            .then(async res => {
             rolenames();
             get_permisiions_details(userDetails.user.firm_id);
             setDeletedRoles(new Set());
             var close_button = document.getElementsByClassName("cancel_icon");
             for(let i=0;i< close_button.length; i++){
                 close_button[i].className ="delete_icon";
                 close_button.src= delete_icon
             }
             return await res.json()
            })
            console.log(result);
        }
        setDeleteVerifier(false);
    })
    .catch(err=>{
        console.log("delte error")
    })
  
   }
   const rolenames =async()=>{
    setRolesLoader(true)
    const result = await fetch(process.env.REACT_APP_API_URL+"/api/roles/list?type="+userDetails.user.user_type+"&firm_id="+userDetails.user.firm_id+`&getFirmDetails =${true}`)
    .then(async(res)=>{
       
        const r = await res.json()
        setRoles(r)
        setRolesLoader(false)
        return r
    })
    
    
    console.log("these are roles",result)
   }
   const updaterole =async(roleId)=>{
    console.log(roleId,"to update id rol")
    role_update_form.permissions_ids = FormData.permissions_ids;
    role_update_form.role_name=FormData.role_name
    role_update_form.description=FormData.description
    const result= await fetch(process.env.REACT_APP_API_URL+"/api/roles/update/"+roleId,{
        method:"POST",
        headers:{
            "Content-Type": "application/json"
        },
        body: JSON.stringify(role_update_form)
    })
    .then(async res=>{
        get_users_list();
        rolenames();
        get_permisiions_details(userDetails.user.firm_id);
        return await res.json()
        
    })
    console.log(result)
    
   }
   const createRole=async()=>{
        const result = await fetch(process.env.REACT_APP_API_URL+"/api/roles/create",{
            method: "POST",
            headers:{
                "Content-Type": "application/json"
            },
            body: JSON.stringify({...FormData})
        })
        .then(async(res)=>{
            return await res.json()
        })
   
        if(result.success){           
            document.getElementById("closeCreateModal").click();
            rolenames();
        }

     }
   useEffect(()=>{
    setFormData({...FormData,permissions_ids:selected_permissions});
    return setFormData({...FormData,permissions_ids:selected_permissions});
   },[selected_permissions])

   const roles_data= (event)=>{
          setFormData({...FormData, [event.target.name]: event.target.value})          
         
        }
    const getAvailablePermissions=async(event)=>{
        let firmId= event.target.value;
        setFormData({...FormData,firm_id:firmId})
        console.log("called vaialable permissions")
        const availablePermissions = await fetch(process.env.REACT_APP_API_URL+"/api/permissions/readByFirm/"+firmId)
            .then(async res=>{
                const result = await res.json();
                console.log("available permissions are ",result)
                setPermissions(result.result);
            })
    }
    const updateRoleFormDdata= async(role,firmId) =>{ 
        if(userDetails.user.user_type == 'superAdmin'){
            const availablePermissions = await fetch(process.env.REACT_APP_API_URL+"/api/permissions/readByFirm/"+firmId)
            .then(async res=>{
                const result = await res.json();
                console.log("available permissions are ",result);
                setPermissions(result.result);
            })
           
            
        }
        setFormData({...role_update_form,role_name:role.role_name,description:role.description, updated_by:{updated_by_id: userDetails.user._id}})      
        role_update_form.permissions_ids = role.permissions_ids
        role_update_form.firm_id = role.firm_id
        role_update_form.role_name=role.role_name
        role_update_form.description=role.description;
        var roleName=document.getElementById(role._id+"_roleName")
        roleName.value=role.role_name
        var roleDescription=document.getElementById(role._id+"_description")
        roleDescription.value=role.description
        setSelectedPermissions(role.permissions_ids);
        permissions.map(permission => {
            var permission_input = document.getElementById(role.role_name+"_"+permission._id);          
        })
        role.permissions_ids.forEach(permission => {
            var permission_input = document.getElementById(role.role_name+"_"+permission.id)
           
        });
        
        console.log(role_update_form,"===================",role,"this is role updated form")
    }

    const changeSelectedPermissions = (permissionId)=>{
      if(selected_permissions.includes(permissionId)){
        let Selected = selected_permissions.filter( permission_id => permission_id != permissionId)
        
        setSelectedPermissions(Selected);
      }
      else{
        setSelectedPermissions([...selected_permissions,permissionId])
      }
    }
    const newFormData = async() => {
        setSelectedPermissions([]);
        if(userDetails.user.user_type == 'superAdmin')setPermissions([]);
        setFormData({...role_create_form, created_by : userDetails.user._id});
    }

    const confirmDeleteRoles = async() => {
        for(let user of deleted_roles){
            var result = await fetch(process.env.REACT_APP_API_URL+"/api/roles/delete/"+user,{
             method: "POST",            
            })       
            .then(async res => {
             rolenames();
             get_permisiions_details(userDetails.user.firm_id);
             setDeletedRoles(new Set());
             var close_button = document.getElementsByClassName("cancel_icon");
             for(let i=0;i< close_button.length; i++){
                 close_button[i].className ="delete_icon";
                 close_button.src= delete_icon
             }
             return await res.json()
            })
            console.log(result);
        }
         setDeleteVerifier(false);
    }
    return(
        <Context.Consumer>
            {value => {
                const { user } = value;               
                return(
                    <div className="user_page "> 
                        <div className="modal fade" id="newrole" tabIndex="-1" aria-labelledby="newroleLabel" aria-hidden="true" >
                            <div className="modal-dialog" >
                                <div className="modal-content" style={{width:"35rem"}}>
                                    <div className="modal-header">
                                        <h5 className="modal-title text-secondary" id="newroleLabel">New Role</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <form>
                                            <div className="mb-3">
                                                <label htmlFor="recipient-name" className="col-form-label" >Role Name</label>
                                                <input type="text" className="form-control" onChange={roles_data} name='role_name' id="recipient-name"/>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="message-text" className="col-form-label" >Description</label>
                                                <textarea className="form-control" id="message-text" onChange={roles_data} name='description'></textarea>
                                            </div>
                                            {userDetails.user.user_type == 'superAdmin'?
                                                <div className="d-flex flex-column">
                                                    <span className="text-primary fs-4">Firm</span>
                                                    <br/>
                                                    <select className='fs-5'  onChange={getAvailablePermissions}>
                                                        <option value="select">---select a firm---</option>
                                                        {
                                                        firms.map((firm,index)=>{
                                                            return <option  key={index} value={firm._id}>{firm.firm_name}</option>
                                                        })
                                                        }
                                                    </select>
                                                </div>
                                            :""}
                                            <div className=' role_card_permissions'>
                                                <p className=' text-success fs-5 fw-bolder mt-3 m-1'>Permissions</p>
                                                <div className='d-flex flex-row flex-wrap justify-content-start align-content-start role_card_permissions_container p-1'>
                                                    {
                                                        permissions.map((permission,index)=> {
                                                            
                                                            return (
                                                                <div key={index} className='d-inline-flex align-items-center p-3'>
                                                                    <input type='checkbox' className='' value={permission._id} id={permission._id} onChange={()=>{changeSelectedPermissions(permission._id)}} />
                                                                    <span>&nbsp;&nbsp;{permission.permission_type}</span>
                                                                </div>)
                                                        })
                                                    }
                                                </div>
            
                                            </div>
                                        </form>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id="closeCreateModal">Close</button>
                                        <button type="button" className="btn btn-primary" onClick={createRole}>Create</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                            
                        <div className="options_container d-flex align-items-center justify-content-end gap-2">   
                            { deleted_roles.size  > 0 ? <div onClick={delete_users} className="btn btn-danger">Delete</div> : ""}      
                            <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#newrole" data-bs-whatever="@mdo" onClick={newFormData}>+ New Role</button>                             
                        </div>
                        {/*------------------Confirm Delete Permissions Modal */}
                  
                        <button type="button" className="btn btn-primary d-none" id="confirmDeleteButton" data-bs-toggle="modal" data-bs-target="#confirmDeleteRoles">
                            Launch demo modal
                        </button>

                        <div className="modal fade" id="confirmDeleteRoles" tabindex="-1" role="dialog" aria-labelledby="confirmDeleteRolesTitle" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered" role="document">
                                <div className="modal-content">
                                <div className="modal-header">
                                    <img src={logo} height="40"/>
                                </div>
                                <div className="modal-body text-secondary fs-6">
                                    Some of the roles selected are currently assigned to some users, Deleting them will result into no availability of the selected roles to any user.
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={()=>{setDeletedRoles(new Set());navigate("/roles")}}>Cancel</button>
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={()=>{confirmDeleteRoles()}}>Delete Anyway</button>
                                </div>
                                </div>
                            </div>
                        </div>
                        {/*--------------------------------------------------- */}
                        <div className="user_cards_container">
                            <div className=" ">
                                <div className="user_detail_card_headings  text-white p-2 bg-primary align-items-center d-flex justify-content-around">
                                        <span className={"fw-bold "+ (userDetails?.user?.user_type == 'superAdmin'?"user_detail_field_superAdmin":"user_detail_field")}>Role Name</span>
                                        {userDetails && userDetails?.user?.user_type === 'superAdmin'?<div className={"fw-bold "+ (userDetails?.user?.user_type == 'superAdmin'?"user_detail_field_superAdmin":"user_detail_field")}>Firm</div>:""}
                                        <span className={"fw-bold "+ (userDetails?.user?.user_type == 'superAdmin'?"user_detail_field_superAdmin":"user_detail_field")}>Description</span>
                                        <span className={"fw-bold "+ (userDetails?.user?.user_type == 'superAdmin'?"user_detail_field_superAdmin":"user_detail_field")}>Permissions</span>
                                        <div className="user_detail_field d-flex align-items-center fw-light text-primary user_search_input_container bg-warning">
                                            <img  height="60%" style={{position:"absolute",right:'2px',zIndex:1,}} src={require("../../assets/search_icon.svg").default}/>
                                            <input className=" fw-light text-primary" style={{textAlign:"center",fontSize:'0.7rem',width:'100%',paddingRight:'15px'}} type="" placeholder="Search Role" onChange={Search_user}/>
                                        </div>
                                </div>
                                {rolesLoader || permissionsLoader ? 
                                <div className="h-100 w-100 d-flex justify-content-center bg-white align-items-center">
                                    <div class="text-center text-primary fw-bolder fs-2">
                                        <div class="spinner-border" role="status"></div>
                                    </div>
                                </div>:
                                    <div className="user_details_table">
                                    {
                                    roles.length === 0 ? <div style={{height:"90%"}} className="no_user bg-white d-flex flex-column justify-content-center align-items-center">
                                                                    <img height="50%" src={require("../../assets/user_not_found.svg").default} alt="user not found" />
                                                                    <p className="fw-bold">No Roles Found</p>
                                                        </div>:
                                                            <div> 
                                                                {roles.map((role,index)=>{
                                                                    return (
//===================update Role Modal================================
                                                                        <div key={index} className="user_detail_card bg-white p-2 d-flex justify-content-around align-items-center">
                                                                            <span className="user_detail_field fw-bold">{role.role.role_name}</span>
                                                                            {userDetails && userDetails?.user?.user_type === 'superAdmin'?<div className={"fw-bold "+ (userDetails?.user?.user_type == 'superAdmin'?"user_detail_field_superAdmin":"user_detail_field")}>{role.firm?.firm_name} </div>:""}
                                                                            <span className="user_detail_field">{role.role.description}</span>
                                                                            <div className="user_detail_field   d-flex gap-1 justify-content-center align-items-center" style={{height:"100%"}}>
                                                                                <div className="btn btn-primary update_button" data-bs-toggle="modal"  data-bs-target={"#updaterole"+`${role.role._id}`} onClick={()=>{updateRoleFormDdata(role.role,role.role.firm_id)}} data-whatever="@getbootstrap" >
                                                                                    View & Update
                                                                                </div>
                                                                                <div className="modal fade " id={"updaterole"+role.role._id} tabIndex="-1" aria-labelledby="updateroleLabel" aria-hidden="true">
                                                                                        <div className="modal-dialog " >
                                                                                            <div className="modal-content" style={{width:"35rem"}}>
                                                                                                <div className="modal-header">
                                                                                                    <h5 className="modal-title text-secondary " id="updateroleLabel">Update Role</h5>
                                                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=>{setSelectedPermissions([])}}></button>
                                                                                                </div>
                                                                                                <div className="modal-body">
                                                                                                    <form>
                                                                                                        <div className="mb-3  text-align-left">
                                                                                                            <label htmlFor="recipient-name " className="col-form-label fw-bolder text-primary text-align-left" >Role Name</label>
                                                                                                            <input type="text" className="form-control" onChange={roles_data} name='role_name' id={role.role._id+"_roleName"} />
                                                                                                            
                                                                                                        </div>
                                                                                                        <div className="mb-3">
                                                                                                            <label htmlFor="message-text" className="col-form-label fw-bolder text-primary" >Description</label>
                                                                                                            <textarea className="form-control" id={role.role._id+"_description"} onChange={roles_data}  name='description' ></textarea>
                                                                                                        </div>
                                                                                                        <div className=' role_card_permissions'>
                                                                                                            <p className=' text-success fs-5 fw-bolder mt-3 m-1'>Permissions</p>
                                                                                                            <div className='d-flex flex-row flex-wrap justify-content-start align-content-start role_card_permissions_container p-1'>
                                                                                                                {
                                                                                                                    permissions.map((permission,index) => {
                                                                                                                        return (
                                                                                                                            <div key={index} className='d-inline-flex align-items-center p-3'>
                                                                                                                                <input type='checkbox' className='' value={permission._id} id={role.role.role_name + "_" + permission._id} onChange={()=>{}} onClick={()=>{changeSelectedPermissions(permission._id)}} checked={selected_permissions.indexOf(permission._id) > -1} />
                                                                                                                                <span>&nbsp;&nbsp;{permission.permission_type}</span>
                                                                                                                            </div>)
                                                                                                                    })
                                                                                                                }
                                                                                                            </div>
                                                                        
                                                                                                        </div>
                                                                                                    </form>
                                                                                                </div>
                                                                                                <div className="modal-footer">
                                                                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id="closeCreateModal"  onClick={()=>{setSelectedPermissions([])}}>Close</button>
                                                                                                    <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={()=>{updaterole(role.role._id)}}>update</button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                </div>
                                                                                
                                                                            </div>
                                                                            <div className="user_detail_field">
                                                                                    <div className="btn btn-danger" value={role.role._id} id={role.role._id} onClick={deleteProfile}>
                                                                                    <img className="delete_icon" src={delete_icon} height="20"/>
                                                                                </div>
                                                                            </div>
                                                                        </div>                                        
                                                                    )                                
                                                                })}
                                                            </div>
                                    }
                                    
                                </div>
                                }
                            
                            </div>
                        </div>
                    </div>
                )
            }}
        </Context.Consumer>
    )
}
