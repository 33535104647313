import logo from './logo.svg';
import './App.css';
import {useState} from 'react'
import Cookies from "js-cookie"
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Home from './components/Home/home'
import ProfileCreate from './components/ProfileCreate/create';
import Login from './components/Login/login';
import NotFound from './components/NotFound/notfound';
import DashBoard from './components/Dashboards/dashboard';
import User from './components/User/user';
import Update from './components/ProfileUpdate/update';
import Firms from './components/Firms/firms';
import FirmCreate from './components/FirmCreate/createFirm';
import FirmUpdate from './components/FirmUpdate/updateFirm';
import Roles from './components/Roles/roles';
import Permissions from './components/Permissions/permissions';
import UserGroups from './components/UserGroups/groups';
import MailSettings from './components/MailSettings/mailSettings';
import ForgotPassword from './components/ForgotPassword/forgotPassword';
import EmailTemplates from './components/EmailTemplates/emailTemplates';
function App() {
  const [isLoggedIn,setIsLoggedIn]=useState(Cookies.get("nexus_login"))
  const SwitchLoginStatus=(Status)=>{
    setIsLoggedIn(Status)
    Cookies.set("nexus_login",Status,{expires:9999})
  }
  if(!isLoggedIn){
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login isLoggedIn={isLoggedIn} SwitchLoginStatus={SwitchLoginStatus}/>} />
          <Route path="forgotPassword" element={<ForgotPassword/>}/>
          <Route path="*" element={<Login/>}/>
        </Routes>
      </BrowserRouter>
    )
  }
  return (
   <BrowserRouter>
      <Routes>
          <Route exact path="/" element={<Home/>}>
              <Route caseSensitive exact path="" element={<DashBoard/>}/>
              <Route caseSensitive exact path="dashboards" element={<DashBoard/>}/> 
              <Route caseSensitive exact path="create-profile" element={<ProfileCreate/>}/>
              <Route caseSensitive exact path='user' element={<User/>}/>
              <Route caseSensitive exact path='update-profile' element={<Update/>}/>
              <Route caseSensitive exact path='firms' element={<Firms/>}/>
              <Route caseSensitive exact path='create-firm' element={<FirmCreate/>}/>
              <Route caseSensitive exact path="update-firm" element={<FirmUpdate/>}/>  
              <Route caseSensitive exact path='roles' element={<Roles/>}/>  
              <Route caseSensitive exact path='permissions' element={<Permissions/>}/>
              <Route caseSensitive exact path='groups' element={<UserGroups/>}/>
              <Route caseSensitive exact path='EmailServices' element={<MailSettings/>}/>
              <Route caseSensitive exact path='EmailTemplates' element={<EmailTemplates/>}/>
              <Route path="*" element={<NotFound/>}/>
          </Route>          
          <Route exact path="/not-found" element={<NotFound/>}/>
          
      </Routes>
   </BrowserRouter>
  );
}

export default App;
