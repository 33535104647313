import './createFirm.css';
import {useState, useEffect} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Cookies from 'js-cookie';
const firm_create_form={
    firm_name: "",
    contact_name: "",
    contact_email: "",
    contact_number: "",
    address:"",
    created_by: ""
}
export default function CreateFirm(props){
    const [FormData, setFormData] = useState(firm_create_form);
    console.log(FormData)
    useEffect(()=>{
        get_created_user_id();
    },[])
    const get_created_user_id = async()=>{
        const created_user_id = await Cookies.get("nexus_user_id");
        setFormData({...FormData, created_by : created_user_id})
    }

    const createFirm=()=>{
       
      
            let input_verifier=true;
            for(let object of Object.keys(FormData)){
                console.log("checking", object, FormData[object], String(FormData[object]).length)
                if(String(FormData[object]).length === 0){
                    input_verifier=false
                    alert("Please fill all the details to create profile")
                    break
                }
                else{
                    console.log("passed", FormData[object])
                }
            }
            if(input_verifier){
                fetch(process.env.REACT_APP_API_URL+"/api/firms/create",{
                    method: "POST",
                    headers:{
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(FormData)
                })
                .then(async(res)=>{
                    const result = await res.json()
                  
                    console.log(result)
                   
                    if (result.success == true){
                        console.log("result uis true for navbigations")
                        window.location.href = "/";
                    }
                   
                    return result
                })
               
            }
        
        
    }

    const InputChange=(event)=>{
        setFormData({...FormData, [event.target.name]: event.target.value})
    }

    return(
        <div className="profile_create_container d-flex flex-column justify-content-center align-items-center w-100">
            <div className='d-flex flex-column align-items-center form_container p-5'>
                <div className='d-flex align-items-center justify-content-center w-100 mb-3'>
                    <img src={require("../../assets/NexusIQ-FinalVersion.jpg")} height="45rem"/>
                </div>
                <div className="d-flex  input_container">
                    <span className='form_label'>FIRM NAME</span>
                    <input  className="input_element form_input_element" type="text" name="firm_name" onChange={InputChange}/>
                </div>
                <div className="d-flex  input_container">
                    <span className='form_label'>CONTACT NAME</span>
                    <input  className="input_element form_input_element" type="text" name="contact_name" onChange={InputChange}/>
                </div>
                <div className="d-flex  input_container">
                    <span className='form_label'>EMAIL</span>
                    <input  className="input_element form_input_element" type="email" name="contact_email" onChange={InputChange}/>
                </div>
                <div className="d-flex input_container">
                    <span className='form_label'>CONTACT NUMBER</span>
                    <input  className="input_element form_input_element" type="text" name="contact_number" onChange={InputChange}/>
                </div>
                <div className="d-flex  input_container">
                    <span className='form_label'>ADDRESS</span>
                    <input  className="input_element form_input_element" type="text" name="address" onChange={InputChange}/>
                </div>
                <div className="d-flex  input_container">
                    <span className='form_label'>STATUS</span>
                    <select className="input_element form_input_element p-1 " name="user_type" onChange={InputChange}>
                        <option value="employee">0</option>
                        <option value="student">1</option>
                    </select>
                </div>
                <input type="button" className="user_create_button mt-4" value="Create Firm" onClick={createFirm}/>
            </div>

        </div>
    )
}