import { useState, useEffect, useContext } from 'react';
import Cookies from 'js-cookie';
import { useMatch, useSearchParams } from 'react-router-dom';
import Context from '../../user_details';
const profile_update_form = {
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    user_type: "admin",
    updated_by: { updated_by_id: await Cookies.get("nexus_user_id") },
    roles: [],
    groups_assigned: []
}
function UpdateProfile(props) {
    const { user } = useContext(Context);
    const [FormData, setFormData] = useState({ ...profile_update_form, ...user });
    const [searchParams] = useSearchParams();
    const [userUpdateData, setUserUpdateData] = useState({});
    const [selectedRoles, setSeletedRoles] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [roles, setRoles] = useState([]);
    const [groups, setGroups] = useState([]);
    const target_user_id = searchParams.get("userId");
    const target_firm_id = searchParams.get("firmId");
    useEffect(() => {
        setFormData({});

        return;
    }, []);

    useEffect(() => {
        console.log(`================grousp are ==================${groups}`)
    }, [groups]);

    useEffect(() => {
        console.log("=================\n", FormData, "\n=====================")
    }, [FormData]);

    useEffect(() => {
        get_update_data();
        get_groups();
    }, []);

    useEffect(() => {
        console.log(userUpdateData, "result response")
    }, [userUpdateData]);

    useEffect(() => {
        console.log("selectedRoles", selectedRoles)
        setFormData({ ...FormData, selectedRoles })
    }, [selectedRoles]);

    useEffect(() => {
        setFormData({ ...FormData, groups_assigned: selectedGroups });
    }, [selectedGroups]);

    useEffect(() => {
        console.log("roles are", roles)
    }, [roles])

    const roleSelection = async (roleId) => {
        console.log("role selectetion invoke")
        if (selectedRoles.includes(roleId)) {
            let RolesSelected = selectedRoles.filter(role_id => role_id != roleId)
            setSeletedRoles(RolesSelected)
        }
        else {
            setSeletedRoles([...selectedRoles, roleId])
        }

    }

    const groupSelection = async (groupId) => {
        console.log("role selectetion invoke")
        if (selectedGroups.includes(groupId)) {
            let GroupsSelected = selectedGroups.filter(group_id => group_id != groupId)
            setSelectedGroups(GroupsSelected)
        }
        else {
            setSelectedGroups([...selectedGroups, groupId])
        }

    }

    const get_groups = async () => {
        const result = await fetch(`${process.env.REACT_APP_API_URL}/api/groups/list?firm_id=${target_firm_id}`)
            .then(data => {
                return data.json();
            });
        setGroups(result);
    }

    const get_update_data = async () => {
        const user_UpdateData = await fetch(process.env.REACT_APP_API_URL + "/api/admin/read/" + target_user_id + `?getRoles=${true}`)
            .then(async res => {
                let result = await res.json();
                console.log("result update date", result);
                setFormData({
                    ...FormData, first_name: result.result.first_name,
                    last_name: result.result.last_name,
                    username: result.result.username,
                    email: result.result.email
                });
                setUserUpdateData(result.result);
                setRoles(result.result.roles);
                setSelectedGroups(result.result.groups_assigned);
                const filteredSelectedRoles = await Promise.all(
                    result.result.selectedRoles.map(selectedRole => {
                        return selectedRole._id;
                    })
                );
                setSeletedRoles(filteredSelectedRoles);
            })
            .catch(err => {
                console.log(err)
            });
    }

    const update_profile = async () => {
        let input_verifier = true;
        // for(let object of Object.keys(FormData)){

        //     if(String(FormData[object]).length === 0){
        //         input_verifier=false;
        //         alert("Please fill all the details to create profile");
        //         break;
        //     }
        //     else{
        //         console.log("passed", FormData[object]);
        //     }
        // }
        if (input_verifier) {
            const result = await fetch(process.env.REACT_APP_API_URL + "/api/admin/update/" + target_user_id, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(FormData)
            })
                .then(async (res) => {
                    const result = await res.json();
                    console.log(result);
                    if (result.success == true) {
                           window.location.href = "/user";
                    }
                    return result
                })
        }
    }

    const InputChange = (event) => {
        setFormData({ ...FormData, [event.target.name]: event.target.value })
    }
    const emptySelectedRoles = async () => {
        setSeletedRoles(userUpdateData.selectedRoles);
        setRoles(roles)
    }

    const emptySelectedGroups = async () => {
        setSelectedGroups(userUpdateData.groups_assigned);
        setGroups(groups)
    }

    const checkSelectedRoles = (roleId) => {
        return selectedRoles.includes(roleId)
    }
    const checkSelectedGroups = (groupId) => {
        return selectedGroups.includes(groupId)
    }

    return (
        <div className="profile_create_container d-flex flex-column justify-content-center align-items-center w-100">
            <div className='d-flex flex-column align-items-center form_container p-5'>
                <div className='d-flex align-items-center justify-content-center w-100 mb-3'>
                    <img src={require("../../assets/NexusIQ-FinalVersion.jpg")} height="45rem" />
                </div>
                <div className="d-flex  input_container">
                    <span className='form_label'>FIRST NAME</span>
                    <input className="input_element form_input_element" type="text" id="profile_update_first_name" name="first_name" onChange={InputChange} defaultValue={userUpdateData.first_name} />
                </div>
                <div className="d-flex  input_container">
                    <span className='form_label'>LAST NAME</span>
                    <input className="input_element form_input_element" type="text" id="profile_update_last_name" name="last_name" onChange={InputChange} defaultValue={userUpdateData.last_name} />
                </div>
                <div className="d-flex  input_container">
                    <span className='form_label'>EMAIL</span>
                    <input className="input_element form_input_element" type="email" id="profile_update_email" name="email" onChange={InputChange} defaultValue={userUpdateData.email} disabled={user.user_type === 'admin'} />
                </div>
                <div className="d-flex  input_container">
                    <span className='form_label'>USER TYPE</span>
                    <select className="input_element form_input_element p-1" name="user_type" id="profile_update_user_type" onChange={InputChange} defaultValue={userUpdateData.user_type}>
                        <option value="admin">Admin</option>
                        <option value="user">User</option>
                    </select>
                </div>
                <div className="d-flex  input_container">
                    <span className='form_label'>Roles</span>
                    <div className='btn btn-primary form_input_element text-white fs-6 fw-bold' data-bs-toggle="modal" data-bs-target="#updateRoleModal">View | Edit</div>
                    <div className="modal fade" id="updateRoleModal" tabindex="-1" aria-labelledby="updateRoleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5 text-secondary" id="updateRoleModalLabel">Roles</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={emptySelectedRoles}></button>
                                </div>
                                <div className="modal-body d-flex flex-wrap gap-2 fs-5  align-items-center" style={{ height: '30vh', overflowY: 'auto' }}>
                                    {roles && roles.map((role, index) => {
                                        return (<div key={index} className="d-flex-inline align-items-center gap-2" >
                                            <input type="checkbox" className="form-check-input" value={role._id} id={role._id} onClick={() => { roleSelection(role._id) }} checked={checkSelectedRoles(role._id)} onChange={() => { }} />
                                            <span className="text-secondary form-check-label" htmlFor={role._id}>&nbsp;{role.role_name}</span>
                                        </div>)
                                    })}


                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={emptySelectedRoles}>Cancel</button>
                                    <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Save changes</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex  input_container">
                    <span className='form_label'>Groups</span>
                    <div className='btn btn-primary form_input_element text-white fs-6 fw-bold' data-bs-toggle="modal" data-bs-target="#updateGroupsModal">View | Edit</div>
                    <div className="modal fade" id="updateGroupsModal" tabindex="-1" aria-labelledby="updateGroupsModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5 text-secondary" id="updateGroupsModalLabel">Groups</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={emptySelectedGroups}></button>
                                </div>
                                <div className="modal-body d-flex flex-wrap gap-2 fs-5  align-items-center" style={{ height: '30vh', overflowY: 'auto' }}>
                                    {groups && groups.map((group, index) => {
                                        return (<div key={index} className="d-flex-inline align-items-center gap-2" >
                                            <input type="checkbox" className="form-check-input" value={group.group._id} id={group.group._id} onClick={() => { groupSelection(group.group._id) }} checked={checkSelectedGroups(group.group._id)} onChange={() => { }} />
                                            <span className="text-secondary form-check-label" htmlFor={group.group._id}>&nbsp;{group.group.name}</span>
                                        </div>)
                                    })}


                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={emptySelectedGroups}>Cancel</button>
                                    <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Save changes</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <input type="button" className="user_create_button mt-4" value="update Profile" onClick={update_profile} />
            </div>

        </div>
    )
}
export default UpdateProfile;