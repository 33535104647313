import {useState, useEffect, useContext} from 'react';
import Context from '../../user_details';
import smtpInfoDocx from '../../assets/smtpInfoDocumentation.docx'
export default function MailSettings() {
    const {user} = useContext(Context);

    const handleSubmit = async(e) => {
        console.log(e.target.value);
        const email = document.getElementById("SMTP-Email").value;
        const authPass = document.getElementById("SMTP_PASS_KEY").value;
        try{
            const result = await fetch(`${process.env.REACT_APP_API_URL}/api/firms/update/${user.firm_id}`,
                                {
                                    method:'POST',
                                    body:JSON.stringify({SMTP_Email: email,
                                                        SMTP_AUTH: authPass}),
                                    headers: {
                                        "Content-Type": "application/json"
                                    }
                                }
                            )
                            .then(async res =>{
                                return res.json();
                            })
            console.log(result)
        }
        catch(e) {
            console.log(e);
        }
    }
    return (
        <form onSubmit={handleSubmit} className='h-100 w-100  d-flex align-items-center flex-column justify-content-center'>
            <p>Enter Email Address used for Email Services</p>
            <input type="email"id="SMTP-Email"/>
            <p>Enter App Auth Pass Key of respective Email Address Account</p>
            <input type="password"  id="SMTP_PASS_KEY"/>
            <div className='btn btn-primary' type="submit" onClick={handleSubmit}>Save</div>
            <a download="smtpInfoCredentials" href={smtpInfoDocx}>Click here to know how to find your SMTP Authenticaton details</a>
        </form>
    )
}