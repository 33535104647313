import {useState, useEffect, useContext} from 'react';
import {useNavigate} from 'react-router-dom'
import Cookies from 'js-cookie';
import './create.css';
import Context from "../../user_details";
const profile_create_form={
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password:"",
    user_type:"",
    created_by: "",
    firm_id:"",
}
function ProfileCreate(props){
    const [FormData, setFormData] = useState(profile_create_form);
    const [FirmsList, setFirmsList] = useState([]);
    const [roles, setRoles] = useState([]);
    const [groups, setGroups] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    var {user} = useContext(Context);
    const navigate = useNavigate();

    useEffect(()=>{
       
        if(user.user_type === 'superAdmin'){
            get_firm_details();
        }
        else{
            set_form_data();
        }
    },[]);

    useEffect(()=>{
        console.log("=====================list firm ======================\n", FirmsList)
    },[FirmsList]);

    useEffect(()=>{
        console.log("=============================roles =====================\n", roles)
    },[roles]);

    useEffect(()=>{
        console.log("============================= selected roles =====================\n", selectedRoles)
    },[selectedRoles]);

    useEffect(()=>{
        console.log("============================= selected groups =====================\n", selectedGroups)
    },[selectedGroups]);

    useEffect(()=>{
        console.log("\n----------------form data-------------\n",FormData)
    },[FormData]);

    const set_form_data = async() => {
        setFormData({...FormData, created_by: user?._id, firm_id: user?.firm_id});
        getRolesOfFirm(user.firm_id);
        getGroupsofFirm(user.firm_id);
    }

    const getRolesOfFirm = async(firmId)=>{
        console.log("getting roles of ",firmId)
        const firm = await fetch(process.env.REACT_APP_API_URL+"/api/roles/list/?firm_id="+firmId+`&type=superAdmin}`)
        .then(async res=>{
            const result = await res.json();
            console.log(result,"this is result")
            setRoles(result)
            return result
        })
        console.log(roles,"firm roles fetchd", firm)
    }

    const getGroupsofFirm = async(firmId) => {
        const firm = await fetch(process.env.REACT_APP_API_URL+"/api/groups/list/?firm_id="+firmId+`&type=superAdmin}`)
        .then(async res=>{
            const result = await res.json();
            console.log(result,"this is result")
            setGroups(result)
            return result
        })
    }

    const create_profile=async()=>{
        if(FormData.password !== FormData.confirm_password) alert("password must match")
        else if(FormData.password.length < 8) alert("password must contain minimum of 8")
        else {
            let input_verifier=true;
            for(let object of Object.keys(FormData)){
                console.log("checking", object, FormData[object], String(FormData[object]).length)
                if(String(FormData[object]).length === 0){
                    input_verifier=false;
                    alert("Please fill all the details to create profile");
                    break;
                }
                else{
                    console.log("passed", FormData[object]);
                }
            }
            if(input_verifier){
                const result = await fetch(process.env.REACT_APP_API_URL+"/api/create",{
                    method: "POST",
                    headers:{
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({...FormData,roles: selectedRoles, groups_assigned: selectedGroups, loggedin_user_firm_id: user.firm_id})
                })
                .then(async(res)=>{
                    const result = await res.json()
                    if(result.success){
                        var message = document.getElementById("success_message_user_create")
                        message.className = "success_message_user_create bg-success text-white p-4";
                        setTimeout(()=>{
                            message.className = "d-none";
                            window.location.href = "/user";
                        },1000)

                    }
                    else{
                        var message = document.getElementById("fail_message_user_create")
                        message.className = "fail_message_user_create bg-danger text-white p-4";
                        setTimeout(()=>{
                            message.className = "d-none";
                        
                        },1000)

                    }
                    console.log(result)
                })
            }
        }
        
    }

    const InputChange=(event)=>{
        if(event.target.value){
            console.log("checking", event.target.name, event.target.value,event.target.name === 'firm_name')
        if ( event.target.name == 'firm_id') {
            getRolesOfFirm(event.target.value);
            getGroupsofFirm(event.target.value);
            setSelectedRoles([]);
        }
        setFormData({...FormData, [event.target.name]: event.target.value})
        }
    }
    const  get_firm_details = async() =>{
        const firmsList = await fetch(process.env.REACT_APP_API_URL+"/api/firms/list")
        .then(async res=>{
            setFormData({...FormData, created_by: user?._id, firm_id: ""});                                
            const result = await res.json();
            setFirmsList(result.results);
            return result
        })                        
    }

    const changeSelectedRole = (roleId) => {
        console.log("changeing")
        if(selectedRoles.includes(roleId)){
            let selectedRolesArray = selectedRoles.filter(RoleId => RoleId!=roleId);
            setSelectedRoles(selectedRolesArray)
        }
        else{
            setSelectedRoles([...selectedRoles,roleId])
        }
    }
    const changeSelectedGroup = (groupId) => {
        if(selectedGroups.includes(groupId)){
            let selectedGroupsArray = selectedGroups.filter(GroupId => GroupId!=groupId);
            setSelectedGroups(selectedGroupsArray)
        }
        else{
            setSelectedGroups([...selectedGroups,groupId])
        }
    }

    return(
        <Context.Consumer>
            {value => {
                const {user} = value;
                return (
                    <div className="profile_create_container d-flex flex-column justify-content-center align-items-center w-100">
                    <div className='d-flex flex-column align-items-center form_container p-5'>
                        <div className='d-flex align-items-center justify-content-center w-100 mb-3'>
                            <img src={require("../../assets/NexusIQ-FinalVersion.jpg")} height="45rem"/>
                        </div>
                        
                        <div className="d-flex  input_container">
                            <span className='form_label'>FIRST NAME</span>
                            <input  className="input_element form_input_element" type="text" name="first_name" onChange={InputChange}/>
                        </div>
                        <div className="d-flex  input_container">
                            <span className='form_label'>LAST NAME</span>
                            <input  className="input_element form_input_element" type="text" name="last_name" onChange={InputChange}/>
                        </div>
                        <div className="d-flex  input_container">
                            <span className='form_label'>EMAIL</span>
                            <input  className="input_element form_input_element" type="email" name="email" onChange={InputChange}/>
                        </div>
                        <div className="d-flex input_container">
                            <span className='form_label'>PASSWORD</span>
                            <input  className="input_element form_input_element" type="password" name="password" onChange={InputChange}/>
                        </div>
                        <div className="d-flex  input_container">
                            <span className='form_label'>CONFIRM PASSWORD</span>
                            <input  className="input_element form_input_element" type="password" name="confirm_password" onChange={InputChange}/>
                        </div>
                        <div className="d-flex  input_container">
                            <span className='form_label'>USER TYPE</span>
                            <select className="input_element form_input_element p-1" name="user_type" onChange={InputChange}>
                                <option value="" >Select User Type</option>
                                <option value="admin">Admin</option>
                                <option value="user">User</option>
                            </select>
                        </div>
                        {
                            user?.user_type === 'superAdmin'?
                                <div className="d-flex  input_container">
                                    <span className='form_label'>Firm Name</span>
                                    <select className="input_element form_input_element p-1" name="firm_id" onChange={InputChange}>
                                        <option value="" className='text-secondary' >---Select a Firm---</option>
                                        {FirmsList.map((firm,index)=>{
                                            return <option value={firm._id} key={index}>{firm.firm_name}</option>
                                        })}
                                    </select>
                                </div>
                                        : ""
                        }
                    
                        <p className='text-success fs-4 fw-bold'>Roles</p>
                        <div className='w-100 p-2 d-flex flex-wrap gap-3 justify-content-around' style={{border: '1px solid black',height:'50vh'}}>
                            {roles.map((role,index)=>{
                                return(
                                    <div key={index} className='d-flex align-items-center p-1 '>
                                        <input type="checkbox" className='m-0 p-0' onChange={()=>{changeSelectedRole(role.role._id)}}/>
                                        <span>{role.role.role_name}</span>
                                    </div>
                                )
                            })}
                        </div>

                        <p className='text-success fs-4 fw-bold'>Groups</p>
                        <div className='w-100 p-2 d-flex flex-wrap gap-3 justify-content-around' style={{border: '1px solid black',height:'50vh'}}>
                            {groups.map((group,index)=>{
                                return(
                                    <div key={index} className='d-flex align-items-center p-1'>
                                        <input type="checkbox" className='m-0 p-0' onChange={()=>{changeSelectedGroup(group.group._id)}}/>
                                        <span>{group.group.name}</span>
                                    </div>
                                )
                            })}
                        </div>
                
                        <input type="button" className="user_create_button mt-4" value="Create Profile" onClick={create_profile}/>
                    </div>
                            <div className='d-none' id="success_message_user_create">User Created Successfully !</div>
                            <div className='d-none' id="fail_message_user_create">User Created failed !</div>
                </div>
                )
            }}
        </Context.Consumer>
    )
}
export default ProfileCreate